// src/Components/DividerElement.tsx
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const DividerContainer = styled.div<{ height: number }>`
  width: 100%;
  height: ${({ height }) => height}px;
  background-color: #ddd;
  margin: 1vh 0;
  position: relative;
`;

const SliderContainer = styled.div`
  position: absolute;
  bottom: -4vh; /* Position the slider below the container */
  left: 50%; /* Center the slider horizontally */
  transform: translateX(-50%); /* Adjust to truly center */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Slider = styled.input`
  width: 100%;
  max-width: 200px;
`;

const ClickableContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 5vh; /* Minimum height for easier clicking */
  cursor: pointer;
  background-color: transparent; /* Transparent background for easy click detection */
  display: flex;
  align-items: center;
`;

interface DividerElementProps {
  onRemove: () => void;
  isPreviewMode: boolean; 
}

const DividerElement: React.FC<DividerElementProps> = ({ onRemove, isPreviewMode }) => {
  const [height, setHeight] = useState<number>(2); // Default height is 2px
  const [showSlider, setShowSlider] = useState<boolean>(false); // Control slider visibility
  const containerRef = useRef<HTMLDivElement>(null);

  const handleHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHeight(Number(e.target.value)); // Update the height based on slider value
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setShowSlider(false); // Hide slider if clicked outside
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <ClickableContainer ref={containerRef} onClick={() => setShowSlider(true)}>
      <DividerContainer height={height}>
   
      </DividerContainer>

      {showSlider && (
        <SliderContainer onClick={(e) => e.stopPropagation()}> {/* Prevent click from propagating to the parent */}
          <Slider
            type="range"
            min="1"
            max="5"
            value={height}
            onChange={handleHeightChange}
          />
        </SliderContainer>
      )}
    </ClickableContainer>
  );
};

export default DividerElement;
