import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import SignIn from './Pages/SignInPage';
import Validation from './Pages/Validate';
import Dashboard from './Pages/Dashboard';
import Gallery from './Components/ComponentPages/Gallery';
import ContactPage from './Components/ComponentPages/Contact';
import FeaturesPage from './Pages/FeaturesPage';
import PricingPage from './Pages/PricingPage';
import ProtectedRoute from './Components/context/ProtectedRoute';
import { CompanyProvider } from './Components/context/CompanyContext';  // Include the CompanyProvider
import './App.css';

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const token = localStorage.getItem('trdtoken');
    
    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }

    setIsLoading(false); // Once the check is done, set loading to false
  }, []);

  return (
    <Router>
      <CompanyProvider>  {/* Wrap everything inside CompanyProvider */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/features" element={<FeaturesPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/validation" element={<Validation />} />

          {/* Protected Dashboard Route */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute 
                isAuthenticated={isAuthenticated}
                isLoading={isLoading} // Pass loading state
                element={<Dashboard />}
              />
            }
          />

          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
      </CompanyProvider>
    </Router>
  );
};

export default App;
