import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import PageNavigation from '../PageNavigation';
import PageFooter from '../PageFooter';
import { CompanyContext } from '../context/CompanyContext';

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Navbar = styled.div`
  background-color: #333;
  color: white;
  padding: 1vh 2vh;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 2000;
  justify-content: space-between;
  align-items: center;
`;

const NavbarButton = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  padding: 1vh 2vh;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #2980b9;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex: 1;
`;

const Sidebar = styled.div`
  width: 20vh;
  padding: 2vh;
  position: sticky;
  top: 8vh;
  height: fit-content;
  z-index: 1500;
`;

const ContentArea = styled.div<{ backgroundColor: string; textColor: string }>`
  flex: 1;
  position: relative;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ textColor }) => textColor};
`;

const PaddedContent = styled.div<{ isPreviewMode: boolean }>`
  padding: 15vh;
  border: ${({ isPreviewMode }) => (isPreviewMode ? 'none' : '2px dashed #ddd')};
  min-height: 50vh;
  box-sizing: border-box;
`;

const NavigationContainer = styled.div`
  padding: 0;
  position: relative;
  top: 0;
  z-index: 1000;
  width: 100%;
`;

const ContactHeader = styled.h1<{ textColor: string }>`
  text-align: center;
  color: ${({ textColor }) => textColor};
  margin-top: 2vh;
  margin-bottom: 8vh;
  font-size: 4vh;
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 2.5vh;
`;

const ContactLink = styled.a`
  font-size: 2.5vh;
  color: #3498db;
  text-decoration: none;
  margin: 2vh 0;

  &:hover {
    text-decoration: underline;
  }
`;

const ColoredSpan = styled.span`
  color: #f39c12; /* Choose the color for the "a" and "e" */
`;

const ContactPage: React.FC = () => {
  const { companyName, companyEmail, companyPhone, name } = useContext(CompanyContext);
  const [backgroundColor, setBackgroundColor] = useState<string>('#ffffff');
  const [textColor, setTextColor] = useState<string>('#000000');
  const [isPreviewMode, setPreviewMode] = useState<boolean>(false);

  const firstName = name.split(' ')[0];

  const calculateBrightness = (color: string) => {
    const rgb = parseInt(color.slice(1), 16);
    const r = (rgb >> 16) & 255;
    const g = (rgb >> 8) & 255;
    const b = (rgb >> 0) & 255;
    return (r * 299 + g * 587 + b * 114) / 1000;
  };

  useEffect(() => {
    const savedBackgroundColor = localStorage.getItem('backgroundColor');
    if (savedBackgroundColor) {
      setBackgroundColor(savedBackgroundColor);
      const brightness = calculateBrightness(savedBackgroundColor);
      setTextColor(brightness < 128 ? '#ffffff' : '#000000');
    }
  }, []);

  return (
    <DashboardContainer>
      <Navbar>
        <div>
      Tr<ColoredSpan>a</ColoredSpan>d<ColoredSpan>e</ColoredSpan>Site
      </div>
        <NavbarButton onClick={() => setPreviewMode(!isPreviewMode)}>
          {isPreviewMode ? 'Exit Preview' : 'Preview'}
        </NavbarButton>
        <NavbarButton>Publish</NavbarButton>
      </Navbar>
      <MainContent>
        {!isPreviewMode && (
          <Sidebar>
          </Sidebar>
        )}
        <ContentArea backgroundColor={backgroundColor} textColor={textColor}>
          <NavigationContainer>
            <PageNavigation />
          </NavigationContainer>
          <PaddedContent isPreviewMode={isPreviewMode}>
            <ContactHeader textColor={textColor}>
              Get in Touch with {firstName} at {companyName}
            </ContactHeader>
            <ContactInfo>
              Contact Email:
              <ContactLink href={`mailto:${companyEmail}`}>{companyEmail}</ContactLink>
              Contact Phone:
              <ContactLink href={`tel:${companyPhone}`}>{companyPhone}</ContactLink>
            </ContactInfo>
          </PaddedContent>
          <PageFooter />
        </ContentArea>
      </MainContent>
    </DashboardContainer>
  );
};

export default ContactPage;
