import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'; // Use axios to interact with the backend
import Nav from '../Components/Nav';


const MainContainer = styled.div`
  background-color: #f7f7f7;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f7f7f7;
`;

const SignInBox = styled.div`
  background-color: white;
  padding: 4vh 5vh;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 40vh;
  text-align: center;
`;


const Title = styled.h2`
  margin-bottom: 2vh;
  font-size: 3vh;
  color: #333;
`;

const Input = styled.input`
  width: 100%;
  padding: 1.5vh;
  margin: 2vh 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 2vh;
`;

const Button = styled.button<{ isDisabled: boolean }>`
  width: 100%;
  padding: 1.5vh;
  background-color: ${(props) => (props.isDisabled ? '#ccc' : '#ffaa42')};
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 2vh;
  margin: 2vh 0;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};

  &:hover {
    background-color: ${(props) => (props.isDisabled ? '#ccc' : '#ffaa42')};
  }
`;

const FooterText = styled.p`
  font-size: 1.8vh;
  color: #888;
  margin-top: 2vh;
`;

const FooterLink = styled(Link)`
  color: #3498db;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const SignIn: React.FC = () => {
  const [isSignUp, setIsSignUp] = useState(false); // State to toggle between sign-in and sign-up
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [isEmailEntered, setIsEmailEntered] = useState(false); // Track if email has been entered for login
  const [errorMessage, setErrorMessage] = useState('');
  const [hasAttemptedLogin, setHasAttemptedLogin] = useState(false);
  const navigate = useNavigate();

  const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:8080';

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      if (!isEmailEntered && email.includes('@')) {
        handleContinue(); // Continue to password if Enter is pressed on email field
      } else if (isFormValid) {
        isSignUp ? handleSignUp() : handleLogin();
      }
    }
  };
  
  // Validate form for both login and signup
  useEffect(() => {
    if (isSignUp) {
      // Sign-up validation (name, email, password, confirm password)
      const isValid =
        name.trim() !== '' &&
        email.includes('@') &&
        password.length >= 6 &&
        password === confirmPassword;
  
      setIsFormValid(isValid);
    } else {
      // Login validation (email, password if the password field is visible)
      const isValid = email.includes('@') && (!isEmailEntered || password.length >= 6);
  
      setIsFormValid(isValid);
    }
  }, [name, email, password, confirmPassword, isSignUp, isEmailEntered]);
 
  const handleLogin = async () => {
    setHasAttemptedLogin(true);  // Set login attempt when trying to log in
    try {
      const response = await axios.post(`${baseURL}/auth/login`, {
        email,
        password,
      });
  
      if (response.status === 200) {
        const { token, userId } = response.data; // Retrieve both token and userId
        localStorage.setItem('trdtoken', token);  // Store token in localStorage
        localStorage.setItem('trduserId', userId); // Store userId in localStorage
        navigate('/dashboard'); // Navigate to dashboard after login
      } else {
        setErrorMessage('Login failed, please try again');
      }
    } catch (error) {
      setErrorMessage('Login failed, please try again');
    }
  };
  
  
  const handleSignUp = async () => {
    if (!isFormValid) return;
  
    try {
      const response = await axios.post(`${baseURL}/auth/register`, {
        name,
        email,
        password,
      });
  
      if (response.status === 201) {
        navigate('/validation'); // Redirect to email validation page
      }
    } catch (error) {
      setErrorMessage('Error during registration. Please try again.');
    }
  };

  const passwordInputRef = useRef<HTMLInputElement | null>(null); // Add reference

const handleContinue = () => {
  setIsEmailEntered(true);
  setTimeout(() => {
    passwordInputRef.current?.focus(); // Focus on password input when it is revealed
  }, 0);
};

  return (
    <MainContainer>
      <Container>
        <Nav />
        <SignInBox>


          {isSignUp ? (
            <>
              <Title>Sign up</Title>
              <p>Create a new account</p>
              <Input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <Input
                type="email"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <Input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <Input
                type="password"
                placeholder="Re-enter Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onKeyDown={handleKeyDown}
              />
                

              <Button isDisabled={!isFormValid} onClick={handleSignUp}>
                Create Account
              </Button>
              

              <FooterText>
                Already have an account?{' '}
                <FooterLink to="#" onClick={() => setIsSignUp(false)}>
                  Sign in
                </FooterLink>
              </FooterText>
            </>
          ) : (
            <>
              <Title>Sign in</Title>
              <p>to continue to your account</p>
              <Input
                type="email"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              {isEmailEntered && (
                  <Input
                    ref={passwordInputRef} // Attach the reference here
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                )}
              {isEmailEntered && hasAttemptedLogin && errorMessage && (
              <p style={{ color: 'red', fontSize: '1.6vh', fontStyle: 'italic' }}>
                {errorMessage}
              </p>
            )}
            <Button isDisabled={!isFormValid} onClick={isEmailEntered ? handleLogin : handleContinue}>
                  {isEmailEntered ? 'Sign in' : 'Continue'}
            </Button>             


              <FooterText>
                Don’t have an account?{' '}
                <FooterLink to="#" onClick={() => setIsSignUp(true)}>
                  Sign up
                </FooterLink>
              </FooterText>
              <FooterText>
                Can’t sign in? <FooterLink to="/reset">Click here</FooterLink>
              </FooterText>
            </>
          )}
        </SignInBox>
      </Container>
    </MainContainer>
  );
};

export default SignIn;
