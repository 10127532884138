import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const BreakContainer = styled.div<{ height: number }>`
  height: ${({ height }) => height}vh; /* Adjust height dynamically */
  width: 100%;
  background-color: transparent;
`;

const SliderContainer = styled.div`
  position: absolute;
  bottom: -4vh; /* Position the slider below the container */
  left: 50%; /* Center the slider horizontally */
  transform: translateX(-50%); /* Adjust to truly center */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Slider = styled.input`
  width: 100%;
  max-width: 200px;
`;

const ParentContainer = styled.div`
  position: relative; /* Ensures the slider is positioned relative to this container */
  margin-bottom: 6vh; /* To give space for the slider when visible */
  cursor: pointer; /* Indicates that the entire container is clickable */
`;

interface BreakElementProps {
  height: number; // Add height prop
  onHeightChange: (newHeight: number) => void; // Add callback to handle height change
  onRemove: () => void;
  isPreviewMode: boolean;
}

const BreakElement: React.FC<BreakElementProps> = ({ height, onHeightChange, onRemove, isPreviewMode }) => {

  const [showSlider, setShowSlider] = useState<boolean>(false); // To control visibility of the slider
  const containerRef = useRef<HTMLDivElement>(null);

  const handleHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newHeight = Number(e.target.value);
    onHeightChange(newHeight); // Update the height via the callback
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setShowSlider(false); // Hide the slider when clicking outside
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <ParentContainer ref={containerRef} onClick={() => setShowSlider(true)}>
      <BreakContainer height={height}>
      </BreakContainer>

      {showSlider && (
        <SliderContainer onClick={(e) => e.stopPropagation()}>
          <Slider
            type="range"
            min="5"
            max="25"
            value={height}
            onChange={handleHeightChange}
          />
        </SliderContainer>
      )}
    </ParentContainer>
  );
};

export default BreakElement;
