import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { fetchUploadedImages } from '../utils/api';
import LazyLoad from 'react-lazyload';

const ImageUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;  /* Centers child elements horizontally */
  justify-content: center;  /* Centers child elements vertically */
  padding: 1vh;
  border-radius: 5px;
   position: relative;
  width: 100%; /* Ensure the container takes up the full width */
  margin-left: auto;  /* Center the container horizontally */
  margin-right: auto;  /* Center the container horizontally */
  z-index: 500;
`;

const ImagePreview = styled.img<{ size: number }>`
  width: ${({ size }) => size}%;  /* Image scales based on the slider */
  max-width: 100%;  /* Prevents the image from exceeding its container's width */
  height: auto;  /* Maintains aspect ratio */
  display: block;
  margin-left: auto;  /* Center the image horizontally */
  margin-right: auto; /* Center the image horizontally */
  object-fit: contain;  /* Ensure the image fits within the container without distortion */
  z-index: 90;
`;

const Slider = styled.input`
  width: 100%;
  margin-top: 1vh;
`;

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  background-color: white;
  padding: 2vh;
  border-radius: 5px;
  width: 80vh;
  height: 70vh;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;

`;

const ImageGallery = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2vh;

`;

const ImageItem = styled.img`
  width: 22vh;
  height: 22vh;
  object-fit: cover;
  cursor: pointer;
  display: block;

`;

const ChangeImageButton = styled.button`
  margin-top: 1vh;
  background-color: #3498db;
  color: white;
  border: none;
  padding: 1vh 2vh;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #2980b9;
  }
`;

const Placeholder = styled.div`
  width: 22vh;
  height: 22vh;
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5vh;
  color: #888;
`;

interface ImageElementProps {
  onImageSelect: (url: string) => void;
  onRemove: () => void;
  existingImage?: string | null;
  size?: number;
  onSizeChange: (size: number) => void;
  isPreviewMode: boolean; // Add the preview mode prop
}


const ImageElement: React.FC<ImageElementProps> = ({
  onImageSelect,
  onRemove,
  existingImage = null,
  size = 50,
  onSizeChange,
  isPreviewMode,  // Use the isPreviewMode prop
}) => {
  const [showModal, setShowModal] = useState(false);
  const [galleryImages, setGalleryImages] = useState<string[]>([]);
  const [selectedImage, setSelectedImage] = useState<string | null>(existingImage);
  const [imageSize, setImageSize] = useState(size);
  const [showSlider, setShowSlider] = useState(false);
  const [showChangeImageButton, setShowChangeImageButton] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const fetchImages = async () => {
    try {
      const images = await fetchUploadedImages();
  
      // Extract just the URLs from the fetched images
      const imageUrls = images.map((image: { url: string }) => image.url);
  
      setGalleryImages(imageUrls); // Set only the URLs in the state
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };
  

  const handleOpenModal = () => {
    setShowModal(true);
    fetchImages();
  };

  const handleImageClick = () => {
    setShowSlider(true); // Show the slider when the image is clicked
    setShowChangeImageButton(true);
  };

  const handleImageSelect = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    onImageSelect(imageUrl);
    setShowModal(false);
    setShowSlider(true); // Show the slider when an image is selected
    setShowChangeImageButton(true); // Show the change image button when an image is selected
  };

  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSize = Number(e.target.value);
    setImageSize(newSize);
    onSizeChange(newSize); // Trigger the size change callback
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setShowSlider(false); // Hide slider when clicked outside
      setShowChangeImageButton(false); // Hide change image button when clicked outside
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (existingImage) {
      setSelectedImage(existingImage); // Ensure the existing image is shown if provided
      setShowChangeImageButton(true); // Show the change button if there's an existing image
    }
  }, [existingImage]);

   const placeholderImage = '/images/placeholder.png';

  return (
    <ImageUploadContainer ref={containerRef}>
      {selectedImage ? (
        <>
          <LazyLoad height={200} offset={100} once>
            <ImagePreview
            onError={(e) => {
              (e.target as HTMLImageElement).src = placeholderImage; // Set placeholder image if the image fails to load
            }}
              src={selectedImage}
              alt="Selected"
              size={imageSize}
              onClick={handleImageClick}
            />
          </LazyLoad>
          {!isPreviewMode && showSlider && (
            <Slider
              type="range"
              min="10"
              max="100"
              value={imageSize}
              onChange={handleSliderChange}
            />
          )}
          {!isPreviewMode && showChangeImageButton && (
            <ChangeImageButton onClick={handleOpenModal}>Change Image</ChangeImageButton>
          )}
        </>
      ) : (
        !isPreviewMode && <button onClick={handleOpenModal}>Choose Image</button>
      )}

      {showModal && (
        <ModalBackdrop onClick={() => setShowModal(false)}>
          <ModalContainer onClick={(e) => e.stopPropagation()}>
            <h3>Select an Image</h3>
            <ImageGallery>
  {galleryImages.map((image, index) => (
    <LazyLoad
      key={index}
      height={200}
      offset={300}
      once
      placeholder={<Placeholder>Loading...</Placeholder>}  // Use the placeholder for loading
    >
      <ImageItem
        src={image}
        alt={`Uploaded ${index}`}
        onClick={() => handleImageSelect(image)}
      />
    </LazyLoad>
  ))}
</ImageGallery>
            <button onClick={() => setShowModal(false)}>Close</button>
          </ModalContainer>
        </ModalBackdrop>
      )}
    </ImageUploadContainer>
  );
};

export default ImageElement;