import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

interface Layout4Props {
  images: string[];
  onRemoveImage: (index: number) => void;
  isPreviewMode: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LargeImageWrapper = styled.div`
  position: relative;
  width: fit-content;
  height: 50vh;
  margin-bottom: 2vh;
  overflow: hidden;

  img {
    width: 100%;
    height: 50vh;
    object-fit: cover;
  }
`;

const ThumbnailContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1vh;
  max-width: 80%; /* Ensure the container doesn't exceed the viewport width */
`;

const ThumbnailWrapper = styled.div`
  position: relative;
  width: calc(10% - 1vh);
  margin: 0.5vh;
  overflow: hidden;
  border-radius: 0.5vh;

  img {
    width: 50vh;
    height: 15vh;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    width: calc(33.33% - 1vh);
  }
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 1vh;
  right: 1vh;
  background-color: rgba(255, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 2vh;
  height: 2vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5vh;
`;

const Layout4: React.FC<Layout4Props> = ({ images, onRemoveImage, isPreviewMode }) => {
  const [largeImageIndex, setLargeImageIndex] = useState<number>(0);

  // Filter out the large image from the thumbnails
  const thumbnailImages = images.filter((_, index) => index !== largeImageIndex);

  useEffect(() => {
    const interval = setInterval(() => {
      setLargeImageIndex((prevIndex) => {
        // Move to the next image, loop back if at the end
        const nextIndex = (prevIndex + 1) % images.length;
        return nextIndex;
      });
    }, 5000); // 5000 milliseconds = 5 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [images.length]);

  // Handler for swapping images when a thumbnail is clicked
  const handleThumbnailClick = (index: number) => {
    setLargeImageIndex(index);
  };

  const placeholderImage = '/images/placeholder.png';

  return (
    <Container>
      {images.length > 0 && (
        <LargeImageWrapper>
          <img src={images[largeImageIndex]} alt={`Gallery item ${largeImageIndex + 1}`} />
          {!isPreviewMode && (
            <RemoveButton onClick={() => onRemoveImage(largeImageIndex)}>x</RemoveButton>
          )}
        </LargeImageWrapper>
      )}
      <ThumbnailContainer>
        {thumbnailImages.map((img, index) => {
          // Adjust the index to account for the removed large image
          const actualIndex = index >= largeImageIndex ? index + 1 : index;
          return (
            <ThumbnailWrapper key={actualIndex}>
              <img  onError={(e) => {
              (e.target as HTMLImageElement).src = placeholderImage; // Set placeholder image if the image fails to load
            }}
                src={img}
                alt={`Thumbnail ${actualIndex + 1}`}
                onClick={() => handleThumbnailClick(actualIndex)}
                style={{ cursor: 'pointer' }}
              />
              {!isPreviewMode && (
                <RemoveButton onClick={() => onRemoveImage(actualIndex)}>x</RemoveButton>
              )}
            </ThumbnailWrapper>
          );
        })}
      </ThumbnailContainer>
    </Container>
  );
};

export default Layout4;
