import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import TitleElement from './TitleElement';
import DescriptionElement from './DescriptionElement';
import DividerElement from './DividerElement';
import ImageElement from './ImageElement';
import ImageDisplayElement from './ImageDisplay';

const TwoPartContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 1vh;
  margin-bottom: 1vh;
  width: 100%;
`;

const SideContainer = styled.div<{ width: string; isPreviewMode: boolean }>`
  width: ${({ width }) => width};
  min-height: 10vh;
  position: relative;
  overflow: hidden;
  transition: width 0.2s ease-in-out;
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 0vh;
  left: 2vh;
  background-color: rgba(7, 92, 141, 1);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 10;
`;

const ResizerBar = styled.div<{ left: string }>`
  width: 1.5vh;
  cursor: col-resize;
  background-color: #ccc;
  height: 100%;
  position: absolute;
  left: ${({ left }) => left};
  top: 0;
  z-index: 100;
  user-select: none;
`;


interface TwoPartLayoutProps {
  layoutId: string;
  onRemove: () => void;
  onDropLeft: (element: any, layoutId: string, e?: React.DragEvent<HTMLDivElement>) => void;  // Include layoutId
  onDropRight: (element: any, layoutId: string, e?: React.DragEvent<HTMLDivElement>) => void; // Include layoutId
  isPreviewMode: boolean;
  leftElement: any;
  rightElement: any;
  leftWidth: string;
  rightWidth: string;
  onRemoveInnerElement: (side: 'left' | 'right') => void; 
  
}

const TwoPartLayout: React.FC<TwoPartLayoutProps> = ({
  layoutId,
  onRemove,
  onDropLeft,
  onDropRight,
  isPreviewMode,
  leftElement,
  rightElement,
  leftWidth: initialLeftWidth,
  rightWidth: initialRightWidth,
  onRemoveInnerElement,
}) => {
  const [leftWidth, setLeftWidth] = useState(initialLeftWidth);
  const [rightWidth, setRightWidth] = useState(initialRightWidth);
  const [resizerLeft, setResizerLeft] = useState(initialLeftWidth);
  const [isResizing, setIsResizing] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!isResizing) return;

      const container = containerRef.current;
      if (!container) return;

      const containerRect = container.getBoundingClientRect();
      const containerWidth = containerRect.width;
      const mouseXRelativeToContainer = e.clientX - containerRect.left;

      const newLeftWidth = ((mouseXRelativeToContainer / containerWidth) * 100).toFixed(2);
      const newRightWidth = (100 - parseFloat(newLeftWidth)).toFixed(2);

      if (parseFloat(newLeftWidth) >= 25 && parseFloat(newLeftWidth) <= 75) {
        setLeftWidth(`${newLeftWidth}%`);
        setRightWidth(`${newRightWidth}%`);
        setResizerLeft(`${newLeftWidth}%`);
      }
    },
    [isResizing]
  );

  const handleMouseUp = () => {
    setIsResizing(false);
  };

  const handleMouseDown = () => {
    setIsResizing(true);
  };

  useEffect(() => {
    if (isResizing) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing, handleMouseMove]);

  const handleDrop = (side: 'left' | 'right', e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation(); // Prevent the event from propagating to parent elements
  
    const elementData = e.dataTransfer.getData('element');
    if (elementData) {
      const element = JSON.parse(elementData);
      console.log(`Element dropped on ${side}:`, element);
      if (side === 'left') {
        onDropLeft(element, layoutId);
      } else {
        onDropRight(element, layoutId);
      }
    }
  };
  

  const allowDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  useEffect(() => {
    console.log("Left Element changed:", leftElement);
  }, [leftElement]);
  
  useEffect(() => {
    console.log("Right Element changed:", rightElement);
  }, [rightElement]);

  return (
    <TwoPartContainer ref={containerRef}>
      <SideContainer isPreviewMode={isPreviewMode} onDragOver={allowDrop} onDrop={(e) => handleDrop('left', e)} width={leftWidth}>
      {!isPreviewMode && (
              <RemoveButton onClick={() => onRemoveInnerElement('left')}>
                x
              </RemoveButton>
            )}
        {leftElement && leftElement.type === 'title' && (
          <TitleElement
            content={leftElement.content || 'Enter Your Title'}
            onUpdateContent={(updatedContent) => {
              onDropLeft({ ...leftElement, content: updatedContent.content }, layoutId);
            }}
            onRemove={() => onRemoveInnerElement('left')}
            isPreviewMode={isPreviewMode}
          />
        )}

{leftElement && leftElement.type === 'description' && (
  <DescriptionElement
    content={leftElement.content || 'Enter your description here...'}
    fontSize={leftElement.fontSize || '16px'}
    fontWeight={leftElement.bold ? 'bold' : 'normal'}
    fontFamily={leftElement.fontFamily || 'Arial, sans-serif'}
    textAlign={leftElement.alignment || 'left'}
    padding={leftElement.padding || '2vh'}
    onPaddingChange={(newPadding) => {
      onDropLeft({ ...leftElement, padding: newPadding }, layoutId);
    }}
    onUpdateContent={(updatedContent) => {
      onDropLeft({ ...leftElement, ...updatedContent }, layoutId);
    }}
    onRemove={() => onRemoveInnerElement('left')}
    isPreviewMode={isPreviewMode}
  />
)}


        {leftElement && leftElement.type === 'divider' && (
          <DividerElement
          onRemove={() => onRemoveInnerElement('left')}
            isPreviewMode={isPreviewMode}
          />
        )}

{leftElement && leftElement.type === 'image' && (
          <ImageElement
            existingImage={leftElement.url || ''}
            size={leftElement.size || 50}
            onImageSelect={(imageUrl) => {
              console.log("Updating left image element URL:", imageUrl);
              onDropLeft({ ...leftElement, url: imageUrl }, layoutId);
            }}
            onSizeChange={(newSize) => {
              console.log("Updating left image element size:", newSize);
              onDropLeft({ ...leftElement, size: newSize }, layoutId);
            }}
            onRemove={() => onRemoveInnerElement('left')}
            isPreviewMode={isPreviewMode}
          />
        )}

{leftElement && leftElement.type === 'imagedisplay' && (
          <ImageDisplayElement
           size={leftElement.size || 50}
            images={leftElement.images || []}
            onImageSelect={(selectedImages) => {
              onDropLeft({ ...leftElement, images: selectedImages }, layoutId);
            }}
            onSizeChange={(newSize) => {
              onDropLeft({ ...leftElement, size: newSize }, layoutId); // Save the new size
            }}
            onRemoveElement={() => onRemoveInnerElement('left')}
            isPreviewMode={isPreviewMode}
          />
        )}

      </SideContainer>

      {!isPreviewMode && <ResizerBar onMouseDown={handleMouseDown} left={resizerLeft} />}

      <SideContainer isPreviewMode={isPreviewMode} onDragOver={allowDrop} onDrop={(e) => handleDrop('right', e)} width={rightWidth}>
      {!isPreviewMode && (
              <RemoveButton onClick={() => onRemoveInnerElement('right')}>
                x
              </RemoveButton>
            )}
        {rightElement && rightElement.type === 'title' && (
          <TitleElement
            content={rightElement.content || 'Enter Your Title'}
            
            onUpdateContent={(updatedContent) => {
              console.log("Updating right element content:", updatedContent);
              onDropRight({ ...rightElement, content: updatedContent.content }, layoutId);
            }}
            onRemove={() => onRemoveInnerElement('right')}
            isPreviewMode={isPreviewMode}
          />
        )}

        {rightElement && rightElement.type === 'description' && (
          <DescriptionElement
          content={rightElement.content || 'Enter your description here...'}
          fontSize={rightElement.fontSize || '16px'}
          fontWeight={rightElement.bold ? 'bold' : 'normal'}
          fontFamily={rightElement.fontFamily || 'Arial, sans-serif'}
          textAlign={rightElement.alignment || 'left'}
          padding={rightElement.padding || '2vh'}
            onPaddingChange={(newPadding) => {
              console.log("Updating right element padding:", newPadding);
              onDropRight({ ...rightElement, padding: newPadding }, layoutId);
            }}
            onUpdateContent={(updatedContent) => {
              console.log("Updating right element content:", updatedContent);
              onDropRight({ ...rightElement, content: updatedContent.content }, layoutId);
            }}
            onRemove={() => onRemoveInnerElement('right')}
            isPreviewMode={isPreviewMode}
          />
        )}

        {rightElement && rightElement.type === 'divider' && (
          <DividerElement
          onRemove={() => onRemoveInnerElement('right')}
            isPreviewMode={isPreviewMode}
          />
        )}

        {rightElement && rightElement.type === 'image' && (
          <ImageElement
            existingImage={rightElement.url || ''}
            size={rightElement.size || 50}
            onImageSelect={(imageUrl) => {
              console.log("Updating right image element URL:", imageUrl);
              onDropRight({ ...rightElement, url: imageUrl }, layoutId);
            }}
            onSizeChange={(newSize) => {
              console.log("Updating right image element size:", newSize);
              onDropRight({ ...rightElement, size: newSize }, layoutId);
            }}
            onRemove={() => onRemoveInnerElement('right')}
            isPreviewMode={isPreviewMode}
          />
        )}

{rightElement && rightElement.type === 'imagedisplay' && (
          <ImageDisplayElement
          size={rightElement.size || 50}
          onSizeChange={(newSize) => {
            onDropRight({ ...rightElement, size: newSize }, layoutId); // Save the new size
          }}
            images={rightElement.images || []}
            onImageSelect={(selectedImages) => {
              onDropRight({ ...rightElement, images: selectedImages }, layoutId);
            }}
            onRemoveElement={() => onRemoveInnerElement('right')}
            isPreviewMode={isPreviewMode}
          />
        )}

      </SideContainer>
    </TwoPartContainer>
  );
};



export default TwoPartLayout;
