import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { CompanyContext } from './context/CompanyContext';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'; // Icons for tick and cross
import { FaSpinner } from 'react-icons/fa'; // Spinner icon
import { FaHdd, FaCloud, FaCloudUploadAlt } from 'react-icons/fa'; // Icons for plans

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2vh;
`;

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
 z-index: 9999;
`;

const ModalContainer = styled.div`
  position: relative;
  background-color: white;
  color: black;
  padding: 4vh;
  border-radius: 10px;
  width: 60vh;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 9999;
`;

const SubdomainField = styled.div`
  display: flex;
  align-items: center;
  font-size: 2vh;
`;

const DomainInputField = styled.input`
  padding: 1vh 1.5vh;
  margin: 2vh 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 2vh;
  width: 100%;
`;

const Button = styled.button`
  padding: 1.5vh 2vh;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 2vh;
  margin-top: 2vh;

  &:hover {
    background-color: #2980b9;
  }
`;

const ModalHeader = styled.h2`
  font-size: 2.5vh;
  margin-bottom: 3vh;
`;

const IconContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.8vh;
`;

const PlanOptionsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 3vh;
`;

const PlanOptionBox = styled.div<{ selected: boolean; isDisabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2vh;
  width: 15vh;
  height: 15vh;
  border: 2px solid ${({ selected }) => (selected ? '#3498db' : '#ccc')};
  border-radius: 10px;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  transition: all 0.3s ease-in-out;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')}; /* Disable clicks if disabled */

  &:hover {
    border-color: ${({ isDisabled }) => (isDisabled ? '#ccc' : '#3498db')};
  }
`;

const PlanIcon = styled.div`
  font-size: 4vh;
  margin-bottom: 1vh;
`;

const PlanPrice = styled.div`
  font-size: 2vh;
  font-weight: bold;
  color: #3498db;
`;

const PlanLabel = styled.div`
  font-size: 1.8vh;
  color: #333;
  text-align: center;
`;

const CustomDomainLink = styled.a`
  color: #3498db;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 1vh;

  &:hover {
    color: #2980b9;
  }
`;

const VerificationInstructions = styled.div`
  margin-top: 2vh;
  font-size: 2vh;
  color: #666;
`;

const DomainInstructionsList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-top: 2vh;
`;

const DomainInstruction = styled.li`
  margin-bottom: 1vh;
  font-size: 1.8vh;
  color: #333;
`;

const VisitorSelect = styled.select`
  padding: 1vh;
  margin-top: 2vh;
  font-size: 2vh;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const TotalPrice = styled.div`
  font-size: 2.2vh;
  font-weight: bold;
  margin-top: 2vh;
`;

interface PublishModalProps {
  setShowModal: (show: boolean) => void;
}

const PublishModal: React.FC<PublishModalProps> = ({ setShowModal }) => {
  const { companyName } = useContext(CompanyContext);
  const [useCustomDomain] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('10'); // Start at £10 plan when using custom domain
  const [subdomain, setSubdomain] = useState(companyName.replace(/\s+/g, '').toLowerCase());
  const [customDomain, setCustomDomain] = useState('');
  const [showCustomDomainInstructions, setShowCustomDomainInstructions] = useState(false);
  const [customDomainEntered, setCustomDomainEntered] = useState(false); // Track if domain is entered
  const [checkingAvailability, setCheckingAvailability] = useState(false);
  const [isAvailable, setIsAvailable] = useState<boolean | null>(null); // To store availability status
  const [additionalVisitors, setAdditionalVisitors] = useState(0);
  const [, setIsPublishing] = useState(false);
  const [elements, setElements] = useState<Element[]>([]);

  const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:8080';

  useEffect(() => {
    const savedElements = localStorage.getItem('savedElements');
    if (savedElements) {
      setElements(JSON.parse(savedElements));  // Load elements from localStorage
    }
  }, []);

  const handleSubdomainInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSubdomain = e.target.value.replace(/\s+/g, '').toLowerCase();
    setSubdomain(newSubdomain);
    setCheckingAvailability(true);
    setIsAvailable(null); // Reset availability status

    // Simulate API request to check subdomain availability
    try {
      const response = await fetch(`${baseURL}/domain/check-availability?subdomain=${newSubdomain}`);
      const { available } = await response.json();
      setIsAvailable(available);
    } catch (error) {
      console.error('Error checking subdomain:', error);
    } finally {
      setCheckingAvailability(false);
    }
  };

  const handlePublish = async () => {
    const token = localStorage.getItem('trdtoken'); // Retrieve token from localStorage
  
    if (!token) {
      alert('You must be logged in to publish a website.');
      return;
    }
  
    // Prepare the data to be sent to the backend
    const requestData = {
      subdomain,
      elements,  // Send the elements array that was retrieved from localStorage
      selectedPlan,  // Include selected plan (e.g., £5, £10, £20 plan)
      additionalVisitors,  // Include additional visitor selections (if any)
    };
  
    setIsPublishing(true); // Start loading spinner or indicate publishing in progress
  
    try {
      const response = await fetch(`${baseURL}/subdomain/publish`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Pass the token for authentication
        },
        body: JSON.stringify(requestData), // Send the request data to the backend
      });
  
      setIsPublishing(false); // Stop loading
  
      if (response.ok) {
        alert('Website published successfully!');
        localStorage.removeItem('savedElements'); // Clear saved elements from localStorage
        setShowModal(false); // Close the modal after publishing
      } else {
        const data = await response.json();
        alert(`Error: ${data.message}`);
      }
    } catch (error) {
      console.error('Error publishing website:', error);
      alert('An error occurred while publishing the website.');
      setIsPublishing(false);
    }
  };

  const handleCustomDomainInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomDomain(e.target.value);
  };

  const handleVerifyCustomDomain = () => {
    setCustomDomainEntered(true); // Simulate domain entry confirmation
  };

  const handleAdditionalVisitorsChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setAdditionalVisitors(Number(e.target.value));
  };

  // Calculate total price based on selected plan and additional visitors
  const basePlanPrice = selectedPlan === '5' ? 5 : selectedPlan === '10' ? 10 : 20;
  const additionalVisitorCost = additionalVisitors; // £1 per 100 visitors
  const totalPrice = basePlanPrice + additionalVisitorCost;

  return (
    <ModalBackdrop onClick={() => setShowModal(false)}>
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <ModalHeader>Publish Your Website</ModalHeader>

        {showCustomDomainInstructions ? (
          customDomainEntered ? (
            <>
              <ModalHeader>Connect Your Domain</ModalHeader>
              <VerificationInstructions>
                Follow these steps to connect your domain:
                <DomainInstructionsList>
                  <DomainInstruction><strong>TXT</strong> Record: _domainconnect.{customDomain}</DomainInstruction>
                  <DomainInstruction><strong>A</strong> Record: 192.0.2.1</DomainInstruction>
                  <DomainInstruction><strong>CNAME</strong> Record for www: www.{customDomain}</DomainInstruction>
                </DomainInstructionsList>
              </VerificationInstructions>
              <Button onClick={() => setShowCustomDomainInstructions(false)}>Back</Button>
            </>
          ) : (
            <>
              <ModalHeader>Enter Your Custom Domain</ModalHeader>
              <DomainInputField
                value={customDomain}
                onChange={handleCustomDomainInput}
                placeholder="yourdomain.com"
              />
              <Button onClick={handleVerifyCustomDomain}>Verify</Button>
            </>
          )
        ) : (
          <>
            <label htmlFor="subdomain">Choose your domain name:</label>
            <SubdomainField>
              <DomainInputField
                id="subdomain"
                value={subdomain}
                onChange={handleSubdomainInput}
                placeholder="Enter your subdomain"
              />
              <span>.trdsite.com</span>
              <IconContainer>
                {checkingAvailability && <FaSpinner className="spin" />}
                {isAvailable === true && <AiOutlineCheck style={{ color: 'green' }} />}
                {isAvailable === false && <AiOutlineClose style={{ color: 'red' }} />}
              </IconContainer>
            </SubdomainField>

            <CheckboxContainer>
              <CustomDomainLink onClick={() => setShowCustomDomainInstructions(true)}>
                Connect My Domain
              </CustomDomainLink>
            </CheckboxContainer>

            <PlanOptionsWrapper>
              <PlanOptionBox selected={selectedPlan === '5'} onClick={() => setSelectedPlan('5')} isDisabled={useCustomDomain}>
                <PlanIcon>
                  <FaHdd />
                </PlanIcon>
                <PlanPrice>£5/month</PlanPrice>
                <PlanLabel>1GB Storage</PlanLabel>
                <PlanLabel><strong>100</strong> Visitors/Month</PlanLabel>
              </PlanOptionBox>

              <PlanOptionBox selected={selectedPlan === '10'} onClick={() => setSelectedPlan('10')}>
                <PlanIcon>
                  <FaCloud />
                </PlanIcon>
                <PlanPrice>£10/month</PlanPrice>
                <PlanLabel>3GB Storage</PlanLabel>
                <PlanLabel><strong>150</strong> Visitors/Month</PlanLabel>
              </PlanOptionBox>

              <PlanOptionBox selected={selectedPlan === '20'} onClick={() => setSelectedPlan('20')}>
                <PlanIcon>
                  <FaCloudUploadAlt />
                </PlanIcon>
                <PlanPrice>£20/month</PlanPrice>
                <PlanLabel>8GB Storage</PlanLabel>
                <PlanLabel><strong>250</strong> Visitors/Month</PlanLabel>
              </PlanOptionBox>
            </PlanOptionsWrapper>

            <label htmlFor="additional-visitors">Additional Monthly Visitors:</label>
        <VisitorSelect
          id="additional-visitors"
          value={additionalVisitors}
          onChange={handleAdditionalVisitorsChange}
        >
          <option value={0}>No additional visitors</option>
          <option value={1}>100 visitors (£1)</option>
          <option value={2}>200 visitors (£2)</option>
          <option value={3}>300 visitors (£3)</option>
          <option value={4}>400 visitors (£4)</option>
          <option value={5}>500 visitors (£5)</option>
          <option value={6}>600 visitors (£6)</option>
          <option value={7}>700 visitors (£7)</option>
          <option value={8}>800 visitors (£8)</option>
          <option value={9}>900 visitors (£9)</option>
          <option value={10}>1000 visitors (£10)</option>
        </VisitorSelect>

        {/* Total Price Display */}
        <TotalPrice>Total: £{totalPrice}/month</TotalPrice>

        <Button onClick={handlePublish}>Publish</Button>
          </>
        )}
      </ModalContainer>
    </ModalBackdrop>
  );
};

export default PublishModal;
