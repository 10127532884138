import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

// Styled button component
const GalleryButton = styled.button<{ buttonColor: string; textColor: string }>`
  background-color: ${({ buttonColor }) => buttonColor || '#3498db'};
  color: ${({ textColor }) => textColor || 'white'};
  border: none;
  padding: 1vh 2vh;
  border-radius: 5px;
  cursor: pointer;
  font-size: 2.5vh;
  margin-top: 4vh;

  &:hover {
    background-color: ${({ buttonColor }) => buttonColor || '#2980b9'};
  }
`;

const ColorPicker = styled.input`
  position: absolute;
  top: 0;
  left: 100%;
  transform: translateX(-50%);
  z-index: 10;
  opacity: 0; /* Hide the color picker, but still clickable */
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

interface GalleryButtonProps {
  isPublished: boolean;
}

const DashboardGalleryButton: React.FC<GalleryButtonProps> = ({ isPublished }) => {
  const [buttonColor, setButtonColor] = useState('#3498db');
  const [textColor, setTextColor] = useState('white'); // Default to white text
  const navigate = useNavigate();
  const colorPickerRef = React.useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (isPublished) {
      navigate('/gallery'); // Navigate to gallery if published
    } else {
      colorPickerRef.current?.click(); // Open the color picker if not published
    }
  };

  const calculateBrightness = (hexColor: string) => {
    const rgb = parseInt(hexColor.slice(1), 16); // Remove "#" and convert to integer
    const r = (rgb >> 16) & 255;
    const g = (rgb >> 8) & 255;
    const b = (rgb >> 0) & 255;
    // Formula to calculate brightness
    return (r * 299 + g * 587 + b * 114) / 1000;
  };

  const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newColor = e.target.value;
    setButtonColor(newColor);

    // Calculate brightness and adjust text color based on background
    const brightness = calculateBrightness(newColor);
    setTextColor(brightness > 128 ? 'black' : 'white'); // Black for light backgrounds, white for dark
  };

  return (
    <div style={{ position: 'relative' }}>
      {/* Gallery button that doubles as a color picker */}
      <GalleryButton buttonColor={buttonColor} textColor={textColor} onClick={handleButtonClick}>
        Go to Gallery
      </GalleryButton>

      {/* Color picker positioned near the button */}
      {!isPublished && (
        <ColorPicker
          ref={colorPickerRef}
          type="color"
          value={buttonColor}
          onChange={handleColorChange}
        />
      )}
    </div>
  );
};

export default DashboardGalleryButton;
