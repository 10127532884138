import React, { createContext, useState, useEffect } from 'react';

interface CompanyContextType {
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  companyName: string;
  setCompanyName: React.Dispatch<React.SetStateAction<string>>;
  companyLogoUrl: string;
  setCompanyLogoUrl: React.Dispatch<React.SetStateAction<string>>;
  trades: string[]; // Add trades to the context
  setTrades: React.Dispatch<React.SetStateAction<string[]>>;
  serviceArea: string; // Add service area to the context
  setServiceArea: React.Dispatch<React.SetStateAction<string>>;
  companyEmail: string; // Add company email field
  setCompanyEmail: React.Dispatch<React.SetStateAction<string>>;
  companyPhone: string; // Add company phone number field
  setCompanyPhone: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
}

export const CompanyContext = createContext<CompanyContextType>({
  name: '',
  setName: () => {},
  companyName: '',
  setCompanyName: () => {},
  companyLogoUrl: '',
  setCompanyLogoUrl: () => {},
  trades: [], // Default empty trades array
  setTrades: () => {},
  serviceArea: '', // Default empty service area
  setServiceArea: () => {},
  companyEmail: '',
  setCompanyEmail: () => {},
  companyPhone: '',
  setCompanyPhone: () => {},
  loading: true,
});

export const CompanyProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [name, setName] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('Your Company');
  const [companyLogoUrl, setCompanyLogoUrl] = useState<string>('');
  const [trades, setTrades] = useState<string[]>([]); // State for trades
  const [serviceArea, setServiceArea] = useState<string>(''); // State for service area
  const [loading, setLoading] = useState<boolean>(true);
  const [companyEmail, setCompanyEmail] = useState<string>(''); // Add state for company email
  const [companyPhone, setCompanyPhone] = useState<string>('');
  
  const baseURL = process.env.NODE_ENV === 'production'
  ? 'https://trdsite-d122ae764dd4.herokuapp.com'
  : 'http://localhost:8080';

  useEffect(() => {
    const fetchCompanyInfo = async () => {
      const token = localStorage.getItem('trdtoken');
      if (!token) {
        console.error('No token found');
        return; // No token, no need to fetch data
      }

      try {
        const response = await fetch(`${baseURL}/auth/get-company-info`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          console.error('Error fetching company info');
          setLoading(false);
          return;
        }

        const data = await response.json();
     
        const fullLogoUrl = data.companyLogoUrl ? `${baseURL}${data.companyLogoUrl}` : '';

        setName(data.name || '');
        setCompanyName(data.companyName || 'Your Company');
        setCompanyLogoUrl(fullLogoUrl);
        setTrades(data.trades || []);
        setServiceArea(data.serviceArea || '');
        setCompanyEmail(data.companyEmail || '');
        setCompanyPhone(data.companyPhone || '');
        setLoading(false);
      } catch (error) {
        console.error('Error fetching company information:', error);
        setLoading(false);
      }
    };

    fetchCompanyInfo();
    // eslint-disable-next-line
  }, []);

  return (
    <CompanyContext.Provider value={{ name, setName, companyEmail, setCompanyEmail, companyPhone, setCompanyPhone, companyName, setCompanyName, companyLogoUrl, setCompanyLogoUrl, trades, setTrades, serviceArea, setServiceArea, loading }}>
      {children}
    </CompanyContext.Provider>
  );
};
