import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import PageNavigation from '../PageNavigation';
import PageFooter from '../PageFooter';
import Elements from '../Elements';
import Layout1 from '../Layout1';
import Layout2 from '../Layout2';
import Layout3 from '../Layout3';
import Layout4 from '../Layout4';
import ImageModal from '../ImageModal';
import { CompanyContext } from '../context/CompanyContext';

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Navbar = styled.div`
  background-color: #333;
  color: white;
  padding: 1vh 2vh;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 2000;
  justify-content: space-between;
  align-items: center;
`;

const NavbarButton = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  padding: 1vh 2vh;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #2980b9;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex: 1;
`;

const Sidebar = styled.div`
  width: 20vh;
  padding: 2vh;
  position: sticky;
  top: 8vh;
  height: fit-content;
  z-index: 1500;
`;

const ContentArea = styled.div<{ backgroundColor: string; textColor: string }>`
  flex: 1;
  position: relative;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ textColor }) => textColor};
`;

const PaddedContent = styled.div<{ isPreviewMode: boolean }>`
  padding: 15vh;
  border: ${({ isPreviewMode }) => (isPreviewMode ? 'none' : '2px dashed #ddd')};
  min-height: 50vh;
  box-sizing: border-box;
`;

const NavigationContainer = styled.div`
  padding: 0;
  position: relative;
  top: 0;
  z-index: 1000;
  width: 100%;
`;

const GalleryHeader = styled.h1<{ textColor: string }>`
  text-align: center;
  color: ${({ textColor }) => textColor};
  margin-top: 2vh;
  margin-bottom: 8vh;
  font-size: 4vh;
`;

const ColoredSpan = styled.span`
  color: #f39c12; /* Choose the color for the "a" and "e" */
`;

interface Image {
  filename: string;
  url: string;
}

const Gallery: React.FC = () => {
  const { companyName } = useContext(CompanyContext);
  const [selectedLayout, setSelectedLayout] = useState<number>(1); // Default layout is 1
  const [images, setImages] = useState<string[]>([]);
  const [backgroundColor, setBackgroundColor] = useState<string>('#ffffff');
  const [textColor, setTextColor] = useState<string>('#000000');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalImageSrc, setModalImageSrc] = useState<string>('');
  const [isPreviewMode, setPreviewMode] = useState<boolean>(false);

  const baseURL = process.env.NODE_ENV === 'production'
  ? 'https://trdsite-d122ae764dd4.herokuapp.com'
  : 'http://localhost:8080';

  useEffect(() => {
    const savedLayout = localStorage.getItem('selectedLayout');
    const savedImages = localStorage.getItem('imagesOrder');

    if (savedLayout) {
      setSelectedLayout(Number(savedLayout)); 
      console.log('Loaded layout from local storage:', savedLayout);
    }

    if (savedImages) {
      try {
        const parsedImages = JSON.parse(savedImages);
        if (Array.isArray(parsedImages) && parsedImages.length > 0) {
          setImages(parsedImages); 
          console.log('Loaded images from local storage:', parsedImages);
        } else {
          fetchImages(); // Fetch images if saved array is empty
        }
      } catch (error) {
        console.error('Error parsing saved images from local storage:', error);
        fetchImages(); 
      }
    } else {
      fetchImages(); 
    }// eslint-disable-next-line
  }, []);

  const fetchImages = async () => {
    try {
      const token = localStorage.getItem('trdtoken');
      if (!token) {
        console.error('No token found, user might not be authenticated');
        return;
      }
  
      const tempResponse = await fetch(`${baseURL}/upload/get-temp-images`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const tempData = await tempResponse.json();
  
      const tempImages: Image[] = tempData.storedImages.map((image: Image) => ({
        ...image,
        url: `${baseURL}${image.url}`,
      }));
  
      const uploadedResponse = await fetch(`${baseURL}/upload/get-images`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const uploadedData = await uploadedResponse.json();
      const cloudinaryImages: Image[] = uploadedData.uploadedImages || [];
  
      // Corrected part: Extracting URLs from both tempImages and cloudinaryImages
      const combinedImages: string[] = [...tempImages.map((image) => image.url), ...cloudinaryImages.map((image) => image.url)];
  
      // Set combined images in state
      setImages(combinedImages);
      localStorage.setItem('imagesOrder', JSON.stringify(combinedImages)); // Save fetched images order in local storage
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };
  

  const handleRemoveImage = (index: number) => {
    const updatedImages = images.filter((_, imgIndex) => imgIndex !== index);
    setImages(updatedImages);
  };

  const handleLayoutChange = (layoutNumber: number) => {
    setSelectedLayout(layoutNumber); 
    localStorage.setItem('selectedLayout', layoutNumber.toString()); // Save layout to local storage
  };

  const handleElementCreate = () => {
    console.log('Create button clicked');
  };

  const openImageModal = (imageSrc: string) => {
    setModalImageSrc(imageSrc);
    setIsModalOpen(true);
  };

  const closeImageModal = () => {
    setIsModalOpen(false);
    setModalImageSrc('');
  };

  const handleImagesReorder = (newImages: string[]) => {
    setImages(newImages);
    localStorage.setItem('imagesOrder', JSON.stringify(newImages)); // Save image order to local storage
  };

  const renderLayout = () => {
    const layoutProps = {
      images,
      onRemoveImage: handleRemoveImage,
      isPreviewMode,
      onImageClick: isPreviewMode ? openImageModal : undefined,
      onImagesReorder: handleImagesReorder,
    };

    switch (selectedLayout) {
      case 1:
        return <Layout1 {...layoutProps} />;
      case 2:
        return <Layout2 {...layoutProps} />;
      case 3:
        return <Layout3 {...layoutProps} />;
      case 4:
        return <Layout4 {...layoutProps} />;
      default:
        return <Layout1 {...layoutProps} />;
    }
  };

  const calculateBrightness = (color: string) => {
    const rgb = parseInt(color.slice(1), 16);
    const r = (rgb >> 16) & 255;
    const g = (rgb >> 8) & 255;
    const b = (rgb >> 0) & 255;
    return (r * 299 + g * 587 + b * 114) / 1000;
  };

  const handleThemeChange = (newBackgroundColor: string) => {
    setBackgroundColor(newBackgroundColor);
    localStorage.setItem('galleryBackgroundColor', newBackgroundColor);

    const brightness = calculateBrightness(newBackgroundColor);
    setTextColor(brightness < 128 ? '#ffffff' : '#000000');
  };

  useEffect(() => {
    const savedBackgroundColor = localStorage.getItem('galleryBackgroundColor');
    if (savedBackgroundColor) {
      setBackgroundColor(savedBackgroundColor);
      const brightness = calculateBrightness(savedBackgroundColor);
      setTextColor(brightness < 128 ? '#ffffff' : '#000000');
    }
  }, []);

  

  return (
    <DashboardContainer>
      <Navbar>
      <div>
      Tr<ColoredSpan>a</ColoredSpan>d<ColoredSpan>e</ColoredSpan>Site
      </div>
        <NavbarButton onClick={() => setPreviewMode(!isPreviewMode)}>
          {isPreviewMode ? 'Exit Preview' : 'Preview'}
        </NavbarButton>
        <NavbarButton>Publish</NavbarButton>
      </Navbar>
      <MainContent>
        {!isPreviewMode && (
          <Sidebar>
            <Elements
             onAddImages={fetchImages}
              onThemeChange={handleThemeChange}
              allowedElements={['addimages', 'image', 'layout1', 'layout2', 'layout3', 'layout4', 'theme']}
              onLayoutSelect={handleLayoutChange}
              onElementCreate={handleElementCreate}
            />
          </Sidebar>
        )}
        <ContentArea backgroundColor={backgroundColor} textColor={textColor}>
          <NavigationContainer>
            <PageNavigation />
          </NavigationContainer>
          <PaddedContent isPreviewMode={isPreviewMode}>
            <GalleryHeader textColor={textColor}>{companyName} Gallery</GalleryHeader>
            {renderLayout()}
            <ImageModal isOpen={isModalOpen} imageSrc={modalImageSrc} onClose={closeImageModal} />
          </PaddedContent>

          <PageFooter />
        </ContentArea>
      </MainContent>
    </DashboardContainer>
  );
};

export default Gallery;
