import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

type TextAlign = 'left' | 'center' | 'right';

const ExplainerContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 10vh 0;
  position: relative;
`;

const ExplainerItem = styled.div<{ textAlign: TextAlign }>`
  text-align: ${({ textAlign }) => textAlign};
  max-width: 25vh;
`;

const Title = styled.h3<{ textAlign: TextAlign }>`
  font-size: 2.5vh;
  margin-bottom: 1vh;
  font-weight: bold;
  text-align: ${({ textAlign }) => textAlign};
`;

const Description = styled.p<{ textAlign: TextAlign }>`
  font-size: 1.8vh;
  font-weight: normal;
  text-align: ${({ textAlign }) => textAlign};
`;

const TextArea = styled.textarea<{ fontSize: string; fontWeight: string; textAlign: TextAlign }>`
  width: 100%;
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  text-align: ${({ textAlign }) => textAlign};
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
  overflow: hidden;
`;

interface ThreePartExplainerProps {
  onRemove: () => void;
  isPreviewMode: boolean; 
}

const ThreePartExplainerElement: React.FC<ThreePartExplainerProps> = ({ onRemove, isPreviewMode }) => {
  const [isEditing, setIsEditing] = useState<{ [key: string]: boolean }>({
    title1: false,
    description1: false,
    title2: false,
    description2: false,
    title3: false,
    description3: false,
  });

  const [texts, setTexts] = useState<{ [key: string]: string }>({
    title1: 'Quality Certified',
    description1: 'We guarantee top-tier service with our certified professionals.',
    title2: 'Friendly Service',
    description2: 'Our team is dedicated to providing customer-friendly interactions.',
    title3: 'Efficient Delivery',
    description3: 'We ensure fast and reliable services for all your needs.',
  });

  const [alignment,] = useState<{ [key: string]: TextAlign }>({
    title1: 'center',
    description1: 'center',
    title2: 'center',
    description2: 'center',
    title3: 'center',
    description3: 'center',
  });

  const textAreaRefs = useRef<{ [key: string]: HTMLTextAreaElement | null }>({});

  const adjustHeight = (target: HTMLTextAreaElement) => {
    target.style.height = 'auto';
    target.style.height = `${target.scrollHeight}px`;
  };

  useEffect(() => {
    Object.keys(isEditing).forEach((key) => {
      if (isEditing[key] && textAreaRefs.current[key]) {
        adjustHeight(textAreaRefs.current[key] as HTMLTextAreaElement);
      }
    });
  }, [isEditing]);

  const handleFocus = (key: string) => {
    setIsEditing((prevState) => ({ ...prevState, [key]: true }));
    if (textAreaRefs.current[key]) {
      adjustHeight(textAreaRefs.current[key] as HTMLTextAreaElement);
    }
  };

  const handleChange = (key: string, value: string) => {
    setTexts((prevState) => ({ ...prevState, [key]: value }));
    if (textAreaRefs.current[key]) {
      adjustHeight(textAreaRefs.current[key] as HTMLTextAreaElement);
    }
  };

  const handleBlur = (key: string) => {
    setIsEditing((prevState) => ({ ...prevState, [key]: false }));
  };

  return (
    <ExplainerContainer>

      <ExplainerItem textAlign={alignment.title1}>
        {isEditing.title1 ? (
          <TextArea
            ref={(el) => (textAreaRefs.current.title1 = el)}
            value={texts.title1}
            onChange={(e) => handleChange('title1', e.target.value)}
            onBlur={() => handleBlur('title1')}
            onFocus={() => handleFocus('title1')}
            fontSize="2.5vh"
            fontWeight="bold"
            textAlign={alignment.title1}
            autoFocus
          />
        ) : (
          <Title onClick={() => handleFocus('title1')} textAlign={alignment.title1}>
            {texts.title1}
          </Title>
        )}
        {isEditing.description1 ? (
          <TextArea
            ref={(el) => (textAreaRefs.current.description1 = el)}
            value={texts.description1}
            onChange={(e) => handleChange('description1', e.target.value)}
            onBlur={() => handleBlur('description1')}
            onFocus={() => handleFocus('description1')}
            fontSize="1.8vh"
            fontWeight="normal"
            textAlign={alignment.description1}
            autoFocus
          />
        ) : (
          <Description onClick={() => handleFocus('description1')} textAlign={alignment.description1}>
            {texts.description1}
          </Description>
        )}
      </ExplainerItem>

      {/* Second Explainer Item */}
      <ExplainerItem textAlign={alignment.title2}>
        {isEditing.title2 ? (
          <TextArea
            ref={(el) => (textAreaRefs.current.title2 = el)}
            value={texts.title2}
            onChange={(e) => handleChange('title2', e.target.value)}
            onBlur={() => handleBlur('title2')}
            onFocus={() => handleFocus('title2')}
            fontSize="2.5vh"
            fontWeight="bold"
            textAlign={alignment.title2}
            autoFocus
          />
        ) : (
          <Title onClick={() => handleFocus('title2')} textAlign={alignment.title2}>
            {texts.title2}
          </Title>
        )}
        {isEditing.description2 ? (
          <TextArea
            ref={(el) => (textAreaRefs.current.description2 = el)}
            value={texts.description2}
            onChange={(e) => handleChange('description2', e.target.value)}
            onBlur={() => handleBlur('description2')}
            onFocus={() => handleFocus('description2')}
            fontSize="1.8vh"
            fontWeight="normal"
            textAlign={alignment.description2}
            autoFocus
          />
        ) : (
          <Description onClick={() => handleFocus('description2')} textAlign={alignment.description2}>
            {texts.description2}
          </Description>
        )}
      </ExplainerItem>

      {/* Third Explainer Item */}
      <ExplainerItem textAlign={alignment.title3}>
        {isEditing.title3 ? (
          <TextArea
            ref={(el) => (textAreaRefs.current.title3 = el)}
            value={texts.title3}
            onChange={(e) => handleChange('title3', e.target.value)}
            onBlur={() => handleBlur('title3')}
            onFocus={() => handleFocus('title3')}
            fontSize="2.5vh"
            fontWeight="bold"
            textAlign={alignment.title3}
            autoFocus
          />
        ) : (
          <Title onClick={() => handleFocus('title3')} textAlign={alignment.title3}>
            {texts.title3}
          </Title>
        )}
        {isEditing.description3 ? (
          <TextArea
            ref={(el) => (textAreaRefs.current.description3 = el)}
            value={texts.description3}
            onChange={(e) => handleChange('description3', e.target.value)}
            onBlur={() => handleBlur('description3')}
            onFocus={() => handleFocus('description3')}
            fontSize="1.8vh"
            fontWeight="normal"
            textAlign={alignment.description3}
            autoFocus
          />
        ) : (
          <Description onClick={() => handleFocus('description3')} textAlign={alignment.description3}>
            {texts.description3}
          </Description>
        )}
      </ExplainerItem>
    </ExplainerContainer>
  );
};

export default ThreePartExplainerElement;
