import React from 'react';

const Validation: React.FC = () => {
  return (
    <div>
      <h1>Email Validation</h1>
      <p>Please check your email for a validation link to complete your registration.</p>
    </div>
  );
};

export default Validation;
