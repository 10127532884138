interface FetchedImage {
  url: string;
  size: number;
}

const baseURL = process.env.NODE_ENV === 'production'
? 'https://trdsite-d122ae764dd4.herokuapp.com'
: 'http://localhost:8080';

export const fetchUploadedImages = async (): Promise<FetchedImage[]> => {
  const token = localStorage.getItem('trdtoken');
  if (!token) {
    console.error('No token found, user might not be authenticated');
    return [];
  }

  try {
    const response = await fetch(`${baseURL}/upload/get-images`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      console.error('Error fetching images:', errorResponse);
      return [];
    }

    const data = await response.json();
    console.log('Fetched combined uploaded images:', data.combinedImages);
    return data.combinedImages || [];  // Return combinedImages, ensuring it's not undefined
  } catch (error) {
    console.error('Error fetching images:', error);
    return [];
  }
};
