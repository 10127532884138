import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaUserCircle } from 'react-icons/fa'; // For the profile icon
import axios from 'axios';

const NavContainer = styled.nav<{ isScrolled: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1vh 2vh;
  background-color: ${({ isScrolled }) => (isScrolled ? '#333' : 'transparent')};
  color: #fff;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  transition: background-color 0.3s ease-in-out;
  box-sizing: border-box;
`;

const Logo = styled.div`
  font-size: 2.6vh;
  font-weight: bold;
`;

const NavLinks = styled.ul`
  list-style: none;
  display: flex;
  gap: 2vh;
`;

const NavLink = styled(Link)<{ isScrolled: boolean }>`
  text-decoration: none;
  color: ${({ isScrolled }) => (isScrolled ? 'white' : 'black')};
  font-size: 2.5vh;

  &:hover {
    color: #ffaa42;
  }
`;

const ColoredSpan = styled.span`
  color: #ffaa42;
`;

const ProfileDropdown = styled.div`
  position: absolute;
  top: 60px;
  right: 20px;
  background-color: white;
  color: black;
  border-radius: 8px;
  padding: 1vh 2vh;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  text-align: center;

  button {
    margin-top: 1vh;
    background-color: #ffaa42;
    border: none;
    padding: 1vh 2vh;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: #ffcc66;
    }
  }
`;

const ProfileIconContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 0.5vw;
`;

const Nav: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  const baseURL = process.env.NODE_ENV === 'production'
  ? 'https://trdsite-d122ae764dd4.herokuapp.com'
  : 'http://localhost:8080';

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    setIsScrolled(scrollTop > 0);
  };

  const handleLogout = () => {
    localStorage.removeItem('trdtoken');
    setIsAuthenticated(false);
    // Additional logout logic
  };

  useEffect(() => {
    const fetchUserEmail = async () => {
      const token = localStorage.getItem('trdtoken');
      if (token) {
        try {
          // Make a GET request to the backend to retrieve user email
          const response = await axios.get(`${baseURL}/auth/get-user-email`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
  
          console.log(response.data.email);  // Debug: Log the response
          setUserEmail(response.data.email);
          setIsAuthenticated(true);
        } catch (error) {
          console.error('Error fetching user email:', error);
          setIsAuthenticated(false);
        }
      } else {
        console.log('No token found in local storage');  // Debug: Log if no token
      }
    };
  
    fetchUserEmail();
    // eslint-disable-next-line
  }, []);
  

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <NavContainer isScrolled={isScrolled}>
      <Logo>
        <NavLink isScrolled={isScrolled} to="/">
          Tr<ColoredSpan>a</ColoredSpan>d<ColoredSpan>e</ColoredSpan>Site
        </NavLink>
      </Logo>
      <NavLinks>
        <li>
          <NavLink isScrolled={isScrolled} to="/">Home</NavLink>
        </li>
        <li>
          <NavLink isScrolled={isScrolled} to="/features">Features</NavLink>
        </li>
        <li>
          <NavLink isScrolled={isScrolled} to="/pricing">Pricing</NavLink>
        </li>
        {isAuthenticated ? (
          <li>
            <ProfileIconContainer onClick={() => setShowDropdown(!showDropdown)}>
              
              <NavLink isScrolled={isScrolled} to="/dashboard">Dashboard</NavLink>
              <FaUserCircle size={24} color={isScrolled ? 'white' : 'black'} />
            </ProfileIconContainer>
            {showDropdown && (
              <ProfileDropdown>
                <p>{userEmail}</p>
                <button onClick={handleLogout}>Log Out</button>
              </ProfileDropdown>
            )}
          </li>
        ) : (
          <li>
            <NavLink isScrolled={isScrolled} to="/signin">Sign In</NavLink>
          </li>
        )}
      </NavLinks>
    </NavContainer>
  );
};

export default Nav;
