import React from 'react';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
  element: React.ReactElement;
  isAuthenticated: boolean;
  isLoading: boolean; // Add a loading state prop
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, isAuthenticated, isLoading }) => {
  if (isLoading) {
    return <div>Loading...</div>; // You can customize this loading state
  }

  return isAuthenticated ? element : <Navigate to="/signin" />;
};

export default ProtectedRoute;
