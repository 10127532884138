import React, { useState } from 'react';
import styled from 'styled-components';
import Nav from '../Components/Nav'; 
import Footer from '../Components/Footer'; 
import { Link } from 'react-router-dom';

const PageContainer = styled.div`
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const HeaderContainer = styled.div`
  background-image: url('/images/tradesitebackground.png');
  background-size: cover;
  background-position: center;
  padding: 10vh 10vw;
  margin-top: 11vh;
  height: 45vh;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const HeaderText = styled.div`
  flex: 1;
  color: white;

  h1 {
    font-size: 4vh;
    font-weight: bold;
    margin-bottom: 2vh;
  }

  p {
    font-size: 2.5vh;
  }
`;

const HeaderImage = styled.div`
  flex: 1;
  video {
    width: 450px;
    height: auto;
    border-radius: 1vh;
    margin-left: 10vh;
  }

  @media (max-width: 768px) {
    margin-top: 3vh;
  }
`;

const StyledLeftHeader = styled.h3`
  color: #ffaa42;
  font-size: 3.5vh;
  text-align: left;
  margin-left: 15vh;
`;


const StyledHeader = styled.h3`
  color: #ffaa42;
  font-size: 4.5vh;
  text-align: center;
`;

const ContentContainer = styled.div`
  flex: 1;
  margin-top: 5vh;
  padding: 5vh 10vw;
`;

const LaptopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
  gap: 5vw;
`;

const CentralImage = styled.img`
  width: 40vw;
  max-width: 500px;
  height: auto;
  border-radius: 10px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 2vw;
  margin-bottom: 3vh;
  width: 100%;
  max-width: 60vw;
  margin: 0 auto;
`;

const Icon = styled.div`
  cursor: pointer;
  padding: 1vh;
  color: #f39c12;
  font-size: 4vh;
  &:hover {
    transform: scale(1.1);
    color: #f39c12;
  }
`;

const ExplainerBox = styled.div`
  background-color: white;
  padding: 4vh;
  height: fit-content;
  border-radius: 8px;
  width: 35%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const ExplainerTitle = styled.h3`
  font-size: 2.8vh;
  color: #ffaa42;
  margin-bottom: 1.5vh;
`;

const ExplainerDescription = styled.p`
  font-size: 2.5vh;
  color: #666;
`;

const CallToAction = styled.div`
  text-align: center;
  margin-top: 5vh;
  margin-bottom: 15vh;
`;

const CallToActionText = styled.h2`
  font-size: 3vh;
  color: #f39c12;
  margin-bottom: 2vh;
`;

const CallToActionButton = styled(Link)`
  background-color: #0ce846;
  color: white;
  padding: 1.7vh 2.4vh;
  text-decoration: none;
  font-size: 2.4vh;
  font-weight: bold;
  border-radius: 5px;
  margin-top: 5vh;
  display: inline-block;
  transition: transform 0.2s ease-in-out; /* Smooth transition for hover effect */

  &:hover {
    background-color: #00ff44;
    transform: scale(1.05); /* Scale the button to 105% of its size */
  }
`;

/* New styled-components for "Why Choose Us?" */
const WhyChooseUsContainer = styled.div`
  margin-top: 0vh;
  text-align: center;
  padding: 5vh 5vh;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
`;

const WhyChooseUsTitle = styled.h2`
  font-size: 3.5vh;
  color: #ffaa42;
  margin-bottom: 3vh;
`;

const WhyChooseUsDescription = styled.p`
  font-size: 2.5vh;
  color: #666;
  line-height: 1.6;
  margin-bottom: 4vh;
`;

const TestimonialsContainer = styled.div`
  padding: 5vh 5vh;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2vw; /* Adjust the gap between testimonials */
`;

const TestimonialWrapper = styled.div`
  flex: 1 1 30%; /* Each testimonial takes 30% of the width */
  max-width: 30%; /* Ensures the testimonials are responsive */
  min-width: 300px; /* Ensures minimum width for smaller screens */
  margin-bottom: 3vh; /* Space between rows */
`;

const Testimonial = styled.blockquote`
  font-size: 2.3vh;
  color: #555;
  margin: 0 auto;
  font-style: italic;
  line-height: 1.5;
`;

const FooterWrapper = styled.div`
  flex: 1 1 30%; /* Ensures it takes the space of the missing third testimonial */
  max-width: 30%;
  min-width: 300px;
  margin-bottom: 3vh;
  display: flex;
  align-items: center; /* Centers the footer text vertically */
  justify-content: center; /* Centers the footer text horizontally */
  color: #888;
`;

const FeaturesPage: React.FC = () => {
  const [activeImage, setActiveImage] = useState('/images/laptop1.png');
  const [description, setDescription] = useState({
    title: 'Fast & Easy Website Builder',
    content: 'Our easy-to-use website builder allows you to create professional websites in just minutes. Designed for busy tradespeople, it offers pre-designed templates that simplify the process without sacrificing quality or flexibility. Get your site up and running quickly and efficiently with just a few clicks.',
  });

  const handleIconClick = (image: string, title: string, content: string) => {
    setActiveImage(image);
    setDescription({ title, content });
  };

  return (
    <>
      <Nav />
      <PageContainer>
        {/* Header Section with Background */}
        <HeaderContainer>
          <HeaderText>
            <h1>FEATURES</h1>
            <p>
              Discover how TradeSite makes it easier to build your online presence. 
              We provide tools for you to <strong>create</strong> professional websites <strong>in seconds</strong>, with no coding required.
            </p>
          </HeaderText>
          <HeaderImage>
          <video 
  src="/images/show.mp4" 
  muted 
  playsInline 
  onMouseOver={(e) => e.currentTarget.play()} 
  onMouseOut={(e) => e.currentTarget.pause()}
>
  Your browser does not support the video tag.
</video>

          </HeaderImage>
        </HeaderContainer>

        {/* Icon and Laptop Section */}
        <ContentContainer>
          <StyledHeader>Discover the Power of TrdSite</StyledHeader>
          <IconContainer>
            <Icon onClick={() => handleIconClick('/images/laptop1.png', 'Fast & Easy Website Builder', 'Our easy-to-use website builder allows you to create professional websites in just minutes. Designed for busy tradespeople, it offers pre-designed templates that simplify the process without sacrificing quality or flexibility.')}>🚀</Icon>
            <Icon onClick={() => handleIconClick('/images/laptop2.png', 'Responsive Design', 'Your website will adapt seamlessly to all devices, ensuring a great user experience whether customers view it from their phone, tablet, or desktop.')}>📱</Icon>
            <Icon onClick={() => handleIconClick('/images/laptop3.png', 'Custom Domains & SEO', 'Enhance your online visibility with custom domain names and SEO-friendly tools. Our platform helps you rank higher in search engine results, bringing more traffic to your site.')}>🌐</Icon>
            <Icon onClick={() => handleIconClick('/images/laptop4.png', 'Image Support', 'Showcase your best work by uploading and displaying high-quality images in galleries. TradeSite supports high-resolution media uploads to ensure your work looks its best.')}>📷</Icon>
            <Icon onClick={() => handleIconClick('/images/laptop5.png', 'Highly Customisable', 'Tailor your website exactly to your needs with our highly customisable templates. Our platform provides flexibility for full customisation without coding knowledge.')}>🛠️</Icon>
          </IconContainer>

          <LaptopSection>
            <CentralImage src={activeImage} alt="Laptop" />
            <ExplainerBox>
              <ExplainerTitle>{description.title}</ExplainerTitle>
              <ExplainerDescription>{description.content}</ExplainerDescription>
            </ExplainerBox>
          </LaptopSection>
        </ContentContainer>

      

        {/* New "Why Choose Us?" Section */}
        <WhyChooseUsContainer>
          <WhyChooseUsTitle>Why Choose Us?</WhyChooseUsTitle>
          <WhyChooseUsDescription>
            Our website builder is highly customisable, allowing you to create your perfect site exactly how you want it. 
            With automatic design adaptation to tablet and mobile devices, your website will always look great, no matter where it's viewed from. 
            We focus on building websites specifically for tradespeople, ensuring all tools and features are optimised for your business needs. 
            Best of all, our platform is far more affordable than other website builders, so you save more money for your business. 
            You remain in full control with dynamic updates on how many visitors you want per month, helping to keep costs down while allowing you to easily upgrade as your traffic grows.
          </WhyChooseUsDescription>
        </WhyChooseUsContainer>

  {/* Call to Action Section */}
  <CallToAction>
          <CallToActionText>Ready to Build Your Perfect Website?</CallToActionText>
          <CallToActionButton to="/signin">Get Started Today!</CallToActionButton>
        </CallToAction>
        <StyledLeftHeader>Don't just take our word for it...</StyledLeftHeader>
        <TestimonialsContainer>
          
        <TestimonialWrapper>
          <Testimonial>
            "TradeSite made it incredibly easy to set up my website. I had no experience, but their platform was intuitive and simple to use."
          </Testimonial>
        </TestimonialWrapper>

        <TestimonialWrapper>
          <Testimonial>
            "I’ve saved so much time and money with TradeSite. The templates are customizable and perfect for my business needs."
          </Testimonial>
        </TestimonialWrapper>

        <TestimonialWrapper>
          <Testimonial>
            "Their customer support is excellent, and the website builder is powerful yet simple. Highly recommended!"
          </Testimonial>
        </TestimonialWrapper>

        <TestimonialWrapper>
          <Testimonial>
            "Being able to control how many visitors I get per month and upgrading as needed has kept my costs down while growing my business."
          </Testimonial>
        </TestimonialWrapper>

        <TestimonialWrapper>
          <Testimonial>
            "Affordable, professional, and easy to use. I love how my website looks on my phone!"
          </Testimonial>
        </TestimonialWrapper>

        {/* TrdSite users footer in place of the third testimonial in the second row */}
        <FooterWrapper>
          - TrdSite users
        </FooterWrapper>
      </TestimonialsContainer>

        <Footer />
      </PageContainer>
    </>
  );
};

export default FeaturesPage;
