import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { CompanyContext } from './context/CompanyContext';

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  background-color: white;
  padding: 3vh;
  border-radius: 10px;
  width: 60vh;
  max-height: 80vh;  /* Set a max height */
  overflow-y: auto;  
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: scale-up 0.3s ease;
  text-align: left;

  @keyframes scale-up {
    from {
      transform: scale(0.9);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

const Title = styled.h2`
  margin-bottom: 3vh;
  color: #333;
`;

const Label = styled.label`
  font-size: 1.8vh;
  margin-bottom: 1vh;
  display: block;
`;

const Input = styled.input`
  width: 100%;
  padding: 1.2vh;
  margin-bottom: 2.5vh;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1.6vh;
`;

const FileInput = styled(Input)`
  padding: 0.5vh;
  cursor: pointer;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2.5vh;
`;

const CheckboxItem = styled.div`
  width: 48%;
  margin-bottom: 1.5vh;
`;

const Button = styled.button`
  padding: 1.5vh 3vh;
  border: none;
  border-radius: 5px;
  background-color: #3498db;
  color: white;
  cursor: pointer;
  font-size: 1.6vh;
  width: 100%;
  text-align: center;

  &:hover {
    background-color: #2980b9;
  }
`;

const LogoPreview = styled.img`
  max-width: 20vh;
  margin-bottom: 2vh;
`;

interface InfoElementProps {
  setShowModal: (show: boolean) => void;
}

const InfoElement: React.FC<InfoElementProps> = ({ setShowModal }) => {
  const { setCompanyName, setCompanyLogoUrl } = useContext(CompanyContext);
  const [companyNameState, setCompanyNameState] = useState('');
  const [companyLogoUrlState, setCompanyLogoUrlState] = useState('');
  const [selectedLogo, setSelectedLogo] = useState<File | null>(null);
  const [selectedTrades, setSelectedTrades] = useState<string[]>([]);
  const [serviceArea, setServiceArea] = useState<string>(''); // Add service area state
  const [companyEmail, setCompanyEmail] = useState<string>(''); // Add email state
  const [companyPhone, setCompanyPhone] = useState<string>(''); // Add phone number state

  const baseURL = process.env.NODE_ENV === 'production'
  ? 'https://trdsite-d122ae764dd4.herokuapp.com'
  : 'http://localhost:8080';

  const trades = [
    'Building work',
    'Landscaping',
    'Ground work',
    'Roofing',
    'Joinery',
    'Carpet fitting',
    'Painting',
    'Plastering',
    'Other',
  ];
  
  useEffect(() => {
    const fetchCompanyInfo = async () => {
      const token = localStorage.getItem('trdtoken');
      if (!token) {
        console.error('No token found, user might not be authenticated');
        return;
      }
  
      try {
        // Fetch company info (name, email, phone, etc.)
        const response = await fetch(`${baseURL}/auth/get-company-info`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          // Update the state with the company info
          setCompanyNameState(data.companyName || '');
          setCompanyEmail(data.companyEmail || '');
          setCompanyPhone(data.companyPhone || '');
          setSelectedTrades(data.trades || []);
          setServiceArea(data.serviceArea || '');
        } else {
          console.error('Error fetching company info');
        }
  
        // Fetch logo separately
        try {
          const logoResponse = await fetch(`${baseURL}/upload/get-logo`, {
            method: 'GET',
          });
  
          if (logoResponse.ok) {
            const logoData = await logoResponse.json();
            const logoUrl = `${baseURL}${logoData.logoUrl}`;
            setCompanyLogoUrlState(logoUrl || '');
            setCompanyLogoUrl(logoUrl || '');
          } else if (logoResponse.status === 404) {
            console.warn('Logo not found');
            setCompanyLogoUrlState(''); // Set to empty or default image
          }
        } catch (logoError) {
          console.error('Error fetching logo:', logoError);
        }
  
      } catch (error) {
        console.error('Error fetching company information:', error);
      }
    };
  
    fetchCompanyInfo();
    // eslint-disable-next-line
  }, [setCompanyLogoUrl, setCompanyName]);
  
  

  const handleLogoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const logoFile = e.target.files[0];
      setSelectedLogo(logoFile);

      const reader = new FileReader();
      reader.onloadend = () => {
        setCompanyLogoUrlState(reader.result as string);
      };
      reader.readAsDataURL(logoFile);
    }
  };

  const handleSave = async () => {
    const token = localStorage.getItem('trdtoken');
    if (!token) {
      console.error('No token found, user might not be authenticated');
      return;
    }
  
    const formData = new FormData();
    formData.append('companyName', companyNameState);
    formData.append('companyEmail', companyEmail);
    formData.append('companyPhone', companyPhone);
    formData.append('trades', JSON.stringify(selectedTrades)); 
    formData.append('serviceArea', serviceArea);
  
    if (selectedLogo) {
      formData.append('companyLogo', selectedLogo); 
    }
  
    try {
      const response = await fetch(`${baseURL}/auth/update-company-info`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
  
      if (!response.ok) {
        const errorResponse = await response.json();
        console.error('Error saving company information:', errorResponse);
        return;
      }
  
      // Update the context or state with the saved company name and logo after successful save
      setCompanyName(companyNameState);
      setShowModal(false); 
    } catch (error) {
      console.error('Error saving company information:', error);
    }
  };
  

  const toggleTradeSelection = (trade: string) => {
    setSelectedTrades((prevTrades) =>
      prevTrades.includes(trade)
        ? prevTrades.filter((t) => t !== trade) // Deselect if already selected
        : [...prevTrades, trade] // Select if not already selected
    );
  };

  return (
    <ModalBackdrop onClick={() => setShowModal(false)}>
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <Title>Company Information</Title>

        <Label>Company Name:</Label>
        <Input
          type="text"
          value={companyNameState}
          placeholder="Enter company name"
          onChange={(e) => setCompanyNameState(e.target.value)}
        />

        <Label>Company Logo:</Label>
        {(companyLogoUrlState || selectedLogo) && (
          <LogoPreview
            src={selectedLogo ? URL.createObjectURL(selectedLogo) : companyLogoUrlState}
            alt="Company Logo"
          />
        )}
        <FileInput type="file" accept="image/*" onChange={handleLogoUpload} />

        <Label>Service Area:</Label> {/* Add service area input */}
        <Input
          type="text"
          value={serviceArea}
          placeholder="Enter service area"
          onChange={(e) => setServiceArea(e.target.value)}
        />

<Label>Company Email:</Label> {/* Add company email input */}
        <Input
          type="email"
          value={companyEmail}
          placeholder="Enter company email"
          onChange={(e) => setCompanyEmail(e.target.value)}
        />

        <Label>Company Phone Number:</Label> {/* Add company phone number input */}
        <Input
          type="tel"
          value={companyPhone}
          placeholder="Enter company phone number"
          onChange={(e) => setCompanyPhone(e.target.value)}
        />

        <Label>What services do you offer:</Label>
        <CheckboxContainer>
          {trades.map((trade, index) => (
            <CheckboxItem key={index}>
              <label>
                <input
                  type="checkbox"
                  checked={selectedTrades.includes(trade)}
                  onChange={() => toggleTradeSelection(trade)}
                />
                {trade}
              </label>
            </CheckboxItem>
          ))}
        </CheckboxContainer>

        <Button onClick={handleSave}>Save</Button>
      </ModalContainer>
    </ModalBackdrop>
  );
};

export default InfoElement;
