import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { CompanyContext } from './context/CompanyContext';

const NavBar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1vh 2vh;
  background-color: #333;
  color: white;
  height: 8vh;
  z-index: 500; 
`;

const Logo = styled.img`
  height: 100%;  /* Ensure the logo takes up the full height of the navbar */
  max-height: 7vh;  /* Set the maximum height to match the navbar height */
  object-fit: contain;  /* Ensure the logo maintains its aspect ratio */
  width: auto;  /* Allow the width to adjust automatically */
`;

const CompanyName = styled.div`
  font-size: 2.5vh;
  font-weight: bold;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 2vh;
`;

const NavLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 2vh;
  &:hover {
    color: #3498db;
  }
`;

interface PageNavigationProps {
  className?: string; // Allow className to be passed
}

const PageNavigation: React.FC<PageNavigationProps> = ({ className }) => {
  const { companyLogoUrl, companyName, loading } = useContext(CompanyContext);
  const [logoDisplayed, setLogoDisplayed] = useState(companyLogoUrl);

  // Re-run this effect when `companyLogoUrl` updates in the context
  useEffect(() => {
  
    setLogoDisplayed(companyLogoUrl); // Ensure state updates correctly
  }, [companyLogoUrl]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <NavBar className={className}>
      {logoDisplayed ? (
        <Logo src={logoDisplayed} alt="Company Logo" />
      ) : (
        <CompanyName>{companyName || 'Your Company Name'}</CompanyName>
      )}
      <NavLinks>
        <NavLink to="/dashboard">Home</NavLink>
        <NavLink to="/gallery">Gallery</NavLink>
        <NavLink to="/contact">Contact</NavLink>
      </NavLinks>
    </NavBar>
  );
};

export default PageNavigation;
