import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { fetchUploadedImages } from '../utils/api';
import DashboardGalleryButton from './GalleryButton';
import LazyLoad from 'react-lazyload';

const ImageUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0vh;
  width: 100%;
  border-radius: 5px;
  position: relative;
   margin-left: auto;
  margin-right: auto;
`;

const ImageContainer = styled.div`
  display: flex;
  gap: 0vh;
  flex-wrap: nowrap;
  justify-content: space-around;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  height: fit-content;
`;

const ImageTopContainer = styled.div`
  padding: 0vh 10vh;
`;

const ImageItemContainer = styled.div`
  width: 33%;
  text-align: center;
  position: relative;
`;

const ImagePreview = styled.img`
  width: 100%;
  height: auto;
  border: 1px solid #ddd;
  object-fit: contain;
  display: block;
  margin: auto;
  cursor: pointer;
`;

const ChangeImageButton = styled.button`
  margin-top: 1vh;
  background-color: #3498db;
  color: white;
  border: none;
  padding: 1vh 2vh;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #2980b9;
  }
`;

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  background-color: white;
  padding: 2vh;
  border-radius: 5px;
  width: 80vh;
  height: 70vh;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
`;

const ImageGallery = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2vh;
`;

const ImageItem = styled.img`
  width: 22vh;
  height: 22vh;
  object-fit: cover;
  cursor: pointer;
`;

interface ImagePreviewProps {
  isPreviewMode: boolean;
  onImageSelect: (index: number, url: string) => void;
  onRemove: (index: number) => void;
  onRemoveElement: () => void;
  images: string[]; // This prop was expected but not used properly
}

const ImagePreviewComponent: React.FC<ImagePreviewProps> = ({
  isPreviewMode,
  onImageSelect,
  onRemove,
  onRemoveElement,
  images,  // Accepting images prop to render selected images
}) => {
  const [showModal, setShowModal] = useState(false);
  const [galleryImages, setGalleryImages] = useState<string[]>([]);
  const [selectedImages, setSelectedImages] = useState<string[]>([]); // Initialize from props
  const [activeSlot, setActiveSlot] = useState<number | null>(null); // Track which slot is active
  const [showChangeButton, setShowChangeButton] = useState<number | null>(null); // Track the image with an active change button
  const containerRef = useRef<HTMLDivElement>(null);
  const isPublished = false;

  // Sync the selectedImages with the passed images prop
  useEffect(() => {
    setSelectedImages(images); // Set selected images from the prop
  }, [images]);

  const fetchImages = async () => {
    try {
      const images = await fetchUploadedImages();
  
      // Extract just the URLs from the fetched images
      const imageUrls = images.map((image: { url: string }) => image.url);
  
      setGalleryImages(imageUrls); // Set only the URLs in the state
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const handleOpenModal = (index: number) => {
    setActiveSlot(index); // Set the active slot
    setShowModal(true);
    fetchImages();
  };

  const handleImageSelect = (imageUrl: string) => {
    if (activeSlot === null) return; // Ensure an active slot is set

    // Ensure the image is inserted into the correct slot
    const updatedImages = [...selectedImages];
    updatedImages[activeSlot] = imageUrl;
    setSelectedImages(updatedImages);
    onImageSelect(activeSlot, imageUrl); // Notify parent of selection
    setShowModal(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node)
    ) {
      setShowChangeButton(null); // Hide the change image button when clicking outside
    }
  };

  const handleImageClick = (index: number) => {
    setShowChangeButton(index); // Show the change button for the clicked image
  };

  // Adding and removing event listeners for detecting clicks outside
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <ImageUploadContainer ref={containerRef}>

      <ImageContainer>
  {selectedImages.map((image, index) => (
    <ImageItemContainer key={index}>
      {image ? (
        <>
          <ImageTopContainer>
          <LazyLoad height={200} offset={100} once>
              <ImagePreview
                src={image}
                alt={`Selected ${index}`}
                onClick={() => handleImageClick(index)} // Show button when image is clicked
              />
            </LazyLoad>
          </ImageTopContainer>
          {!isPreviewMode && showChangeButton === index && (
            <ChangeImageButton onClick={() => handleOpenModal(index)}>
              Change Image
            </ChangeImageButton>
          )}
        </>
      ) : (
        !isPreviewMode && (
          <button onClick={() => handleOpenModal(index)}>
            Choose Image {index + 1}
          </button>
        )
      )}
    </ImageItemContainer>
  ))}
</ImageContainer>


      <DashboardGalleryButton isPublished={isPublished} />

      {showModal && (
        <ModalBackdrop onClick={() => setShowModal(false)}>
          <ModalContainer onClick={(e) => e.stopPropagation()}>
            <h3>Select an Image</h3>
            <ImageGallery>
              {galleryImages.map((image, index) => (
                <ImageItem
                  key={index}
                  src={image}
                  alt={`Uploaded ${index}`}
                  onClick={() => handleImageSelect(image)}
                />
              ))}
            </ImageGallery>
            <button onClick={() => setShowModal(false)}>Close</button>
          </ModalContainer>
        </ModalBackdrop>
      )}
    </ImageUploadContainer>
  );
};

export default ImagePreviewComponent;
