import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface ThemeElementProps {
  onChangeTheme: (color: string) => void;
}

const ColorInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2vh;
`;

const ColorLabel = styled.label`
  margin-bottom: 1vh;
  font-size: 2vh;
  font-weight: bold;
`;

const ColorInput = styled.input`
  width: 15vh;
  height: 5vh;
  cursor: pointer;
`;

const ThemeElement: React.FC<ThemeElementProps> = ({ onChangeTheme }) => {
  const [color, setColor] = useState<string>('#ffffff'); // Default color is white

  // Retrieve the color from localStorage when the component mounts
  useEffect(() => {
    const savedColor = localStorage.getItem('themeColor');
    if (savedColor) {
      setColor(savedColor);
      onChangeTheme(savedColor); // Apply the saved color immediately
    }
  }, [onChangeTheme]);

  const handleColorChange = (colorValue: string) => {
    setColor(colorValue);
    onChangeTheme(colorValue); // Trigger the change when user selects a color
    localStorage.setItem('themeColor', colorValue); // Save the selected color in localStorage
  };

  return (
    <ColorInputContainer>
      <ColorLabel>Choose Background Color:</ColorLabel>
      <ColorInput
        type="color"
        value={color}
        onChange={(e) => handleColorChange(e.target.value)}
      />
    </ColorInputContainer>
  );
};

export default ThemeElement;
