import React, { useState } from 'react';
import styled from 'styled-components';

interface Layout1Props {
  images: string[];
  onRemoveImage: (index: number) => void;
  isPreviewMode: boolean;
  onImageClick?: (imageSrc: string) => void;
  onImagesReorder: (newImages: string[]) => void;
}

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1vh;
  padding: 1vh;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr); /* Adjust for smaller screens */
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr; /* Single column on very small screens */
  }
`;

const ImageWrapper = styled.div<{ isSelected: boolean; gridColumn?: string; gridRow?: string }>`
  position: relative;
  grid-column: ${(props) => props.gridColumn || 'span 1'};
  grid-row: ${(props) => props.gridRow || 'span 1'};
  overflow: hidden;
  border-radius: 8px;
  border: ${({ isSelected }) => (isSelected ? '2px solid blue' : 'none')}; /* Highlight selected images */

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 1vh;
  right: 1vh;
  background-color: rgba(255, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 3vh;
  height: 3vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2vh;
  z-index: 1;
`;

const Layout1: React.FC<Layout1Props> = ({
  images,
  onRemoveImage,
  isPreviewMode,
  onImageClick,
  onImagesReorder,
}) => {
  const [selectedImageIndices, setSelectedImageIndices] = useState<number[]>([]);

  // Swap the positions of two images
  const swapImages = (index1: number, index2: number) => {
    const newImages = [...images];
    [newImages[index1], newImages[index2]] = [newImages[index2], newImages[index1]];
    onImagesReorder(newImages);
    setSelectedImageIndices([]); // Clear selection after swapping
  };

  const handleImageClick = (index: number) => {
    if (isPreviewMode) return; // Disable in preview mode

    if (selectedImageIndices.length === 0) {
      setSelectedImageIndices([index]); // Select the first image
    } else if (selectedImageIndices.length === 1) {
      const [firstIndex] = selectedImageIndices;
      swapImages(firstIndex, index); // Swap images
    }
  };

  // Assign varying grid positions to images for visual appeal
  const imageGridPositions = images.map((_, index) => {
    // Every 5th image will be larger
    if (index % 5 === 0) {
      return { gridColumn: 'span 2', gridRow: 'span 2' }; // Larger image
    }
    return { gridColumn: 'span 1', gridRow: 'span 1' }; // Regular-sized image
  });

  const placeholderImage = '/images/placeholder.png';

  return (
    <GridContainer>
      {images.map((img, index) => (
        <ImageWrapper
          key={index}
          gridColumn={imageGridPositions[index].gridColumn}
          gridRow={imageGridPositions[index].gridRow}
          isSelected={selectedImageIndices.includes(index)} // Highlight selected image
          onClick={() => handleImageClick(index)} // Handle image click
        >
        <img
            src={img}
            alt={`Gallery item ${index + 1}`}
            onError={(e) => {
              e.currentTarget.src = placeholderImage; // Fallback to placeholder image on error
            }}
            onClick={() => {
              if (isPreviewMode && onImageClick) {
                onImageClick(img);
              }
            }}
            style={{ cursor: isPreviewMode ? 'pointer' : 'grab' }}
          />
          {!isPreviewMode && (
            <RemoveButton
            onClick={(e) => {
              e.stopPropagation();
              onRemoveImage(index);
            }}
          >
            ×
          </RemoveButton>
          
          )}
        </ImageWrapper>
      ))}
    </GridContainer>
  );
};

export default Layout1;
