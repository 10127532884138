import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Styled Components for Footer
const FooterContainer = styled.footer`
  background-color: #333;
  color: white;
  padding: 4vh 5vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FooterLeft = styled.div`
  flex: 1;
  padding: 1vh;

  p {
    margin-bottom: 2vh;
    font-size: 2vh;
  }
`;

const FooterRight = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: 1vh;
  
  ul {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 2vh;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 1vh;
    }
  }

  li {
    margin-bottom: 1vh;
  }

  a {
    color: white;
    text-decoration: none;
    font-size: 2.2vh;

    &:hover {
      color: #3498db;
    }
  }
`;

const FooterLogo = styled.div`
  font-size: 2.5vh;
  font-weight: bold;
  margin-bottom: 2vh;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterLeft>
        <FooterLogo>TradeSite</FooterLogo>
        <p>&copy; {new Date().getFullYear()} TradeSite Ltd. All rights reserved.</p>
      </FooterLeft>

      <FooterRight>
        <ul>
          <li><Link to="/privacy">Privacy Policy</Link></li>
          <li><Link to="/terms">Terms of Service</Link></li>
          <li><Link to="/contact">Contact Us</Link></li>
        </ul>
      </FooterRight>
    </FooterContainer>
  );
};

export default Footer;
