import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { fetchUploadedImages } from '../utils/api';
import LazyLoad from 'react-lazyload';

// Fade-in animation
const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

// Container that ensures everything is stacked vertically
const ColumnContainer = styled.div`
  position: relative; /* Add this line */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;


// The container that will grow and shrink with the slider
const ResizableContainer = styled.div<{ size: number }>`
  width: ${({ size }) => size}vh; /* Adjust container width based on slider value */
  height: ${({ size }) => size}vh; /* Adjust container height based on slider value */
  overflow: hidden;
  min-height: 10vh;  
  position: relative; 
  margin-left: auto;
  margin-right: auto;
  transition: width 0.5s ease, height 0.5s ease; /* Smooth transition */
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  
`;

const FadeImage = styled.img<{ isActive: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  transition: opacity 1s ease-in-out;
  animation: ${({ isActive }) => (isActive ? fadeIn : 'none')} 1s ease-in-out;
`;

const AddImageButton = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  padding: 1vh 2vh;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1vh;
  &:hover {
    background-color: #2980b9;
  }
`;

const Slider = styled.input`
  width: 100%;
  margin-top: 1vh;
  z-index: 1000;
`;

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  background-color: white;
  padding: 2vh;
  border-radius: 5px;
  width: 80vh;
  max-width: 100%;
  max-height: 70vh;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Spread out content */
  align-items: center; /* Center content */
`;

const ModalHeader = styled.h3`
  margin: 0;
  margin-bottom: 2vh;
  text-align: center;
`;

const ModalButton = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  padding: 1vh 2vh;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 2vh;
  width: 100%;

  &:hover {
    background-color: #2980b9;
  }
`;

const ImageGallery = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2vh;
  max-height: 50vh;
  width: 100%;
  overflow-y: auto;
  justify-content: center;
`;

const ImageItem = styled.img`
  width: 22vh;
  height: 22vh;
  object-fit: cover;
  cursor: pointer;
`;

const ImageWrapper = styled.div`
  position: relative;
`;

const Checkbox = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 2vh;
  height: 2vh;
`;



interface ImageDisplayProps {
  images: string[];
  size: number; // Add a size prop to handle the size externally
  onImageSelect: (selectedImages: string[]) => void;
  onSizeChange: (newSize: number) => void; // Callback to notify the parent of size changes
  onRemoveElement: () => void;
  isPreviewMode?: boolean;
}

const ImageDisplayElement: React.FC<ImageDisplayProps> = ({
  images,
  size, // Use size passed from the parent
  onImageSelect,
  onSizeChange, // Add this callback for when the size changes
  onRemoveElement,
  isPreviewMode,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [galleryImages, setGalleryImages] = useState<string[]>([]);
  const [selectedImages, setSelectedImages] = useState<string[]>(images);
  const [selectedForSelection, setSelectedForSelection] = useState<string[]>([]);
  const [sliderVisible, setSliderVisible] = useState(false); // To control the slider visibility
  const [containerSize, setContainerSize] = useState<number>(size); // Control the size of the container (vh units)
  const containerRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    // Sync the container size with the prop size whenever the prop changes
    setContainerSize(size);
  }, [size]);

const fetchImages = async () => {
  try {
    const fetchedImages = await fetchUploadedImages();

    // Extract just the URLs from the fetched images
    const imageUrls = fetchedImages.map((image: { url: string }) => image.url);

    setGalleryImages(imageUrls); // Set only the URLs in the state
  } catch (error) {
    console.error('Error fetching images:', error);
  }
};


  const handleOpenModal = () => {
    setShowModal(true);
    fetchImages();
  };

  useEffect(() => {
    setSelectedImages(images);
  }, [images]);

  useEffect(() => {
    const loadImages = async () => {
      const availableImages = await fetchUploadedImages(); // Returns FetchedImage[]
      
      // Extract just the URLs
      const imageUrls = availableImages.map((image: { url: string }) => image.url);
  
      setGalleryImages(imageUrls); // Set only URLs (strings)
    };
    loadImages();
  }, []);
  
  useEffect(() => {
    if (isPreviewMode && selectedImages.length > 0) {
      const intervalId = setInterval(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % selectedImages.length);
      }, 3000);
      return () => clearInterval(intervalId);
    }
  }, [isPreviewMode, selectedImages]);

  // Handle selecting or deselecting an image in the modal
  const toggleImageSelection = (imageUrl: string) => {
    setSelectedForSelection((prevSelected) =>
      prevSelected.includes(imageUrl)
        ? prevSelected.filter((img) => img !== imageUrl)
        : [...prevSelected, imageUrl]
    );
  };

  const handleMultipleImageSelect = () => {
    const updatedImages = [...selectedImages, ...selectedForSelection];
    setSelectedImages(updatedImages);
    onImageSelect(updatedImages);
    setSelectedForSelection([]);
    setShowModal(false);
  };

  // Handle click events to show/hide slider
  const handleClickContainer = () => {
    setSliderVisible(true);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      containerRef.current && !containerRef.current.contains(event.target as Node) &&
      sliderRef.current && !sliderRef.current.contains(event.target as Node) &&
      buttonRef.current && !buttonRef.current.contains(event.target as Node)
    ) {
      setSliderVisible(false); // Hide slider when clicking outside
    }
  };
  
  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSize = Number(e.target.value);
    setContainerSize(newSize);  // Update local state
    onSizeChange(newSize);  // Notify parent of size change
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
    
      <ColumnContainer>

        <ResizableContainer size={containerSize} ref={containerRef} onClick={handleClickContainer}>
        
          <ImageContainer>
            {selectedImages.map((image, index) => (
               <LazyLoad height={200} offset={100} key={index} once>
               <FadeImage
                 key={index}
                 src={image}
                 alt={`Slide ${index + 1}`}
                 isActive={index === activeIndex}
               />
             </LazyLoad>
            ))}
          </ImageContainer>
        </ResizableContainer>

        {sliderVisible && (
  <>
    <AddImageButton ref={buttonRef} onClick={handleOpenModal}>Add Images</AddImageButton>
    <Slider
              ref={sliderRef}
              type="range"
              min="10"
              max="100"
              value={size}
              onChange={handleSliderChange} // Pass the change to the parent
            />
  </>
)}

      </ColumnContainer>

      {showModal && (
        <ModalBackdrop onClick={() => setShowModal(false)}>
          <ModalContainer onClick={(e) => e.stopPropagation()}>
            <ModalHeader>Select and Upload Images</ModalHeader>

            <ImageGallery>
              {galleryImages.map((image, index) => {
                const imageUrl = image;
                return (
                  <ImageWrapper key={index}>
                    <ImageItem src={imageUrl} alt={`Gallery ${index}`} />
                    <Checkbox
                      type="checkbox"
                      checked={selectedForSelection.includes(imageUrl)}
                      onChange={() => toggleImageSelection(imageUrl)}
                    />
                  </ImageWrapper>
                );
              })}
            </ImageGallery>

            <ModalButton onClick={handleMultipleImageSelect}>Add Selected Images</ModalButton>
            <ModalButton onClick={() => setShowModal(false)}>Close</ModalButton>
          </ModalContainer>
        </ModalBackdrop>
      )}
    </>
  );
};

export default ImageDisplayElement;
