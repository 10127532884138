import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { FaAlignLeft, FaAlignCenter, FaAlignRight, FaBold, FaUnderline } from 'react-icons/fa';

type TextAlign = 'left' | 'center' | 'right';

const DescriptionContainer = styled.div<{ textAlign: TextAlign; fontFamily: string; fontSize: string; padding: string }>`
  position: relative;
  padding-left: ${({ padding }) => `calc(${padding}vh + 1%)`};  // Adjust with calc
  padding-right: ${({ padding }) => `calc(${padding}vh + 1%)`};
  padding-top: 2vh;
  padding-bottom: 2vh;
  border-radius: 5px;
  box-sizing: border-box;
  min-height: 10vh;
  max-width: 100%;
  text-align: ${({ textAlign }) => textAlign};
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: ${({ fontSize }) => fontSize};

  /* Mobile devices */
  @media (max-width: 768px) {
    padding-left: ${({ padding }) => `calc(${padding}vh * 0.5)`};
    padding-right: ${({ padding }) => `calc(${padding}vh * 0.5)`};
  }

  /* Tablets */
  @media (max-width: 1024px) {
    padding-left: ${({ padding }) => `calc(${padding}vh * 0.75)`};
    padding-right: ${({ padding }) => `calc(${padding}vh * 0.75)`};
  }
`;


const EditableInput = styled.textarea<{ textAlign: TextAlign; fontSize: string; fontFamily: string }>`
  width: 100%;
  border: none;
  background-color: transparent;
  outline: none;
  text-align: ${({ textAlign }) => textAlign};
  font-size: ${({ fontSize }) => fontSize};
  font-family: ${({ fontFamily }) => fontFamily};
  resize: none; /* Disable manual resizing */
  overflow: hidden; /* Hide scrollbar */
`;

const ControlContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1vh;
`;

const ControlButton = styled.button<{ active?: boolean }>`
  background-color: ${({ active }) => (active ? '#ddd' : 'transparent')};
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 0.5vh;
  cursor: pointer;
  margin: 0 0.5vh;
  font-size: 1.5vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #f0f0f0;
  }

  svg {
    font-size: 1.5vh;
  }
`;

const Select = styled.select`
  margin: 0 0.5vh;
  padding: 0.5vh;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1.5vh;
  cursor: pointer;
`;

const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 1vh;
`;

const SliderLabel = styled.span`
  margin-right: 0.5vh;
  font-size: 1.5vh;
`;

const SliderInput = styled.input`
  width: 10vh;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const UnderlinedText = styled.span`
  text-decoration: underline;
`;

const fontSizeMapping: { [key: string]: string } = {
  '16': '2vh',
  '18': '2.5vh',
  '20': '3vh',
  '22': '3.5vh',
  '24': '4vh',
  '28': '4.5vh',
};


interface DescriptionElementProps {
  content: string;
  fontSize?: string;
  fontFamily?: string;
  fontWeight?: string;
  textAlign?: TextAlign;
  padding: string; // Add padding to the props
  isPreviewMode: boolean; 
  onPaddingChange: (newPadding: string) => void;
  onUpdateContent: (props: {
    content: string;
    fontSize: string;
    fontWeight: string;
    fontFamily: string;
    textAlign: TextAlign;
    padding: string;
  }) => void;
  onRemove: () => void;
}

const DescriptionElement: React.FC<DescriptionElementProps> = ({
  content = 'Enter your description here...',
  fontSize = '16',
  fontWeight = 'bold',
  fontFamily = 'Arial, sans-serif',
  textAlign = 'left',
  padding, // Use padding from props
  isPreviewMode,  // Add isPreviewMode prop
  onPaddingChange,
  onUpdateContent,
  onRemove,
}) => {
  const [text, setText] = useState<string>(content);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [alignment, setAlignment] = useState<TextAlign>(textAlign);
  const [size, setSize] = useState<string>(fontSize);
  const [font, setFont] = useState<string>(fontFamily);
  const [showControlPanel, setShowControlPanel] = useState<boolean>(false);
  const [isBold, setIsBold] = useState<boolean>(false);
  const [isUnderline, setIsUnderline] = useState<boolean>(false);
  const descriptionRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setText(content);
    setAlignment(textAlign);
    setSize(fontSize); // This should be the raw value like '16', '18', etc.
    setFont(fontFamily);
  }, [content, textAlign, fontSize, fontFamily, fontWeight]);
  

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (descriptionRef.current && !descriptionRef.current.contains(event.target as Node)) {
        setShowControlPanel(false); // Close the control panel when clicking outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const adjustTextareaHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      inputRef.current.style.height = inputRef.current.scrollHeight + 'px';
    }
  };
  

  useEffect(() => {
    if (isEditing) {
      adjustTextareaHeight();
    }
  }, [isEditing]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
    onUpdateContent({
      content: e.target.value,
      fontSize: size, // Pass the raw value
      fontWeight,
      fontFamily: font,
      textAlign: alignment,
      padding,
    });
    adjustTextareaHeight();
  };
  

  const handlePaddingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  const newPadding = parseInt(e.target.value, 10);  // Parse the value as a number
  onPaddingChange(newPadding.toString());  // Convert number to string before passing
};

  

  const handleAlignmentChange = (align: TextAlign) => {
    setAlignment(align);
    onUpdateContent({
      content: text,
      fontSize: size,
      fontWeight: isBold ? 'bold' : 'normal',
      fontFamily: font,
      textAlign: align,
      padding,
    });
  };

  const handleFontSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedSize = e.target.value;
    setSize(selectedSize); // Keep the select box in sync with the selected raw value
  
    // Pass the selectedSize directly to onUpdateContent
    onUpdateContent({
      content: text,
      fontSize: selectedSize, // Use selectedSize here
      fontWeight: isBold ? 'bold' : 'normal',
      fontFamily: font,
      textAlign: alignment,
      padding,
    });
  };
  
  
  
  const fontSizeOptions = ['16', '18', '20', '22', '24', '28'];

  const handleFontFamilyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newFont = e.target.value;
    setFont(newFont);
    onUpdateContent({
      content: text,
      fontSize: size,
      fontWeight: isBold ? 'bold' : 'normal',
      fontFamily: newFont,
      textAlign: alignment,
      padding,
    });
  };

  const handleEditClick = () => {
    setIsEditing(true);
    setShowControlPanel(true);
  };

  const handleStyleChange = (style: 'bold' | 'underline') => {
    if (style === 'bold') {
      const newBold = !isBold;
      setIsBold(newBold);
      onUpdateContent({
        content: text,
        fontSize: size,
        fontWeight: newBold ? 'bold' : 'normal',
        fontFamily: font,
        textAlign: alignment,
        padding,
      });
    } else if (style === 'underline') {
      const newUnderline = !isUnderline;
      setIsUnderline(newUnderline);
      onUpdateContent({
        content: text,
        fontSize: size,
        fontWeight: isBold ? 'bold' : 'normal',
        fontFamily: font,
        textAlign: alignment,
        padding,
      });
    }
  };

  const renderFormattedText = (text: any) => {
    // Safeguard to ensure text is always a string
    if (typeof text !== 'string') {
      text = String(text);  // Convert non-string values to string
    }
  
    const lines = text.split('\n').map((line: string, index: number) => (
      <React.Fragment key={index}>
        {isBold && isUnderline ? (
          <BoldText>
            <UnderlinedText>{line}</UnderlinedText>
          </BoldText>
        ) : isBold ? (
          <BoldText>{line}</BoldText>
        ) : isUnderline ? (
          <UnderlinedText>{line}</UnderlinedText>
        ) : (
          line
        )}
        <br />
      </React.Fragment>
    ));
    return lines;
  };
  

  return (
    <DescriptionContainer ref={descriptionRef} textAlign={alignment} fontFamily={font} fontSize={fontSizeMapping[size]} padding={padding}>

      {!isPreviewMode && showControlPanel && (
        <ControlContainer>
          <ControlButton active={alignment === 'left'} onClick={() => handleAlignmentChange('left')}>
            <FaAlignLeft />
          </ControlButton>
          <ControlButton active={alignment === 'center'} onClick={() => handleAlignmentChange('center')}>
            <FaAlignCenter />
          </ControlButton>
          <ControlButton active={alignment === 'right'} onClick={() => handleAlignmentChange('right')}>
            <FaAlignRight />
          </ControlButton>
          <ControlButton onClick={() => handleStyleChange('bold')} active={isBold}>
            <FaBold />
          </ControlButton>
          <ControlButton onClick={() => handleStyleChange('underline')} active={isUnderline}>
            <FaUnderline />
          </ControlButton>
          <Select value={size} onChange={handleFontSizeChange}>
  {fontSizeOptions.map((option) => (
    <option key={option} value={option}>
      {option} {/* Show number like 16, 18, etc. */}
    </option>
  ))}
</Select>
          <Select value={font} onChange={handleFontFamilyChange}>
          <option value="Arial, sans-serif">Arial</option>
          <option value="'Times New Roman', serif">Times New Roman</option>
          <option value="'Verdana', sans-serif">Verdana</option>
          <option value="'Trebuchet MS', sans-serif">Trebuchet MS</option>
          <option value="'Lucida Sans', 'Lucida Grande', 'Lucida Sans Unicode', sans-serif">Lucida Sans</option>
          <option value="'Tahoma', sans-serif">Tahoma</option>
          <option value="'Gill Sans', sans-serif">Gill Sans</option>
          <option value="'Palatino', 'Palatino Linotype', 'Book Antiqua', serif">Palatino</option>
          <option value="'Ubuntu', sans-serif">Ubuntu</option>
          </Select>
          <SliderContainer>
            <SliderLabel>Padding:</SliderLabel>
            <SliderInput type="range" min="0" max="35" value={parseInt(padding)} onChange={handlePaddingChange} />
          </SliderContainer>
        </ControlContainer>
      )}


      {isEditing ? (
     <EditableInput
     ref={inputRef}
     value={text}
     onChange={handleChange}
     onBlur={() => setIsEditing(false)}
     onFocus={handleEditClick}
     autoFocus
     textAlign={alignment}
     fontSize={fontSizeMapping[size]} // Map the raw size to the 'vh' value
     fontFamily={font}
   />
   
    
      ) : (
        <div
  onClick={handleEditClick}
  style={{ textAlign: alignment, fontSize: fontSizeMapping[size], fontFamily: font }}
>
  {renderFormattedText(text)}
</div>

      )}
    </DescriptionContainer>
  );
};

export default DescriptionElement;