import React, { useState } from 'react';
import styled from 'styled-components';

interface Layout2Props {
  images: string[];
  onRemoveImage: (index: number) => void;
  isPreviewMode: boolean;
  onImageClick?: (imageSrc: string) => void;
  onImagesReorder: (newImages: string[]) => void;
}

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 equal columns */
  grid-auto-rows: 30vh; /* Default row height */
  gap: 1vh;
  padding: 1vh;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const ImageWrapper = styled.div<{ isSelected: boolean; offset?: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  grid-row: ${(props) => (props.offset ? 'span 2' : 'span 1')}; /* Middle column spans two rows */
  overflow: hidden;

  /* Use box-shadow instead of border to prevent layout shift */
  box-shadow: ${({ isSelected }) =>
    isSelected ? '0 0 0 3px blue' : 'none'};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 1vh;
  right: 1vh;
  background-color: rgba(255, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 2vh;
  height: 2vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5vh;
`;

const Layout2: React.FC<Layout2Props> = ({
  images,
  onRemoveImage,
  isPreviewMode,
  onImageClick,
  onImagesReorder,
}) => {
  const [firstSelectedIndex, setFirstSelectedIndex] = useState<number | null>(null);

  const handleImageClick = (index: number) => {
    if (isPreviewMode) {
      if (onImageClick) {
        onImageClick(images[index]);
      }
    } else {
      if (firstSelectedIndex === null) {
        setFirstSelectedIndex(index); // Select the first image
      } else {
        const newImages = [...images];
        const temp = newImages[firstSelectedIndex];
        newImages[firstSelectedIndex] = newImages[index];
        newImages[index] = temp;
        setFirstSelectedIndex(null);
        onImagesReorder(newImages);
      }
    }
  };

  const placeholderImage = '/images/placeholder.png';

  return (
    <GridContainer>
      {images.map((img, index) => (
        <ImageWrapper
          key={index}
          isSelected={firstSelectedIndex === index}
          onClick={() => handleImageClick(index)}
          offset={index % 3 === 1} /* Offset the middle column */
        >
          <img  onError={(e) => {
              (e.target as HTMLImageElement).src = placeholderImage; // Set placeholder image if the image fails to load
            }} src={img} alt={`Gallery item ${index + 1}`} />
          {!isPreviewMode && (
            <RemoveButton
            onClick={(e) => {
              e.stopPropagation();
              onRemoveImage(index);
            }}
          >
            ×
          </RemoveButton>
          
          )}
        </ImageWrapper>
      ))}
    </GridContainer>
  );
};

export default Layout2;
