import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import Elements from '../Components/Elements';
import TitleElement from '../Components/TitleElement'; 
import DescriptionElement from '../Components/DescriptionElement';
import TwoPartLayout from '../Components/TwoPartLayout';
import BreakElement from '../Components/BreakElement';
import DividerElement from '../Components/DividerElement';
import ThemeElement from '../Components/ThemeElement';
import PageNavigation from '../Components/PageNavigation';
import PageFooter from '../Components/PageFooter';
import ThreePartExplainerElement from '../Components/ThreePartExplainer';
import ImageElement from '../Components/ImageElement';
import ImagePreviewComponent from '../Components/ImagePreview';
import ImageDisplayElement from '../Components/ImageDisplay';
import PublishModal from '../Components/PublishModal';
import { CompanyContext } from '../Components/context/CompanyContext';
import { v4 as uuidv4 } from 'uuid'; 


const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Navbar = styled.div`
  background-color: #333;
  color: white;
  padding: 1vh 2vh;
  display: flex;
  position: sticky;
  top: 0; /* Ensures the navbar stays stuck to the top */
  z-index: 2000;
  justify-content: space-between;
  align-items: center;
`;

const NavbarButton = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  padding: 1vh 2vh;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #2980b9;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex: 1;
`;

const CenteredWrapper = styled.div`
  flex-grow: 1;
  width: 100%;
  margin: 0;
  padding: 0;
`;



const Sidebar = styled.div`
  width: 20vh;
  padding: 2vh;
  position: sticky;
  top: 8vh; /* Adjust to match the height of your navbar */
  height: fit-content;
  z-index: 1500; /* Lower than the Navbar, but high enough to stay above content */

  @media (orientation: portrait) {
    width: 100vw; /* Full width when in portrait mode */
    position: fixed;
    bottom: 0;  /* Stick the sidebar to the bottom */
    top: unset; /* Remove the top positioning */
    height: 10vh; /* Reduce the height in portrait mode */
    z-index: 2000; /* Ensure it stays above other elements */
    display: flex;
    justify-content: center; /* Center the contents */
    padding: 1vh; /* Adjust the padding to fit the new layout */
  }
`;


const ContentArea = styled.div<{ backgroundColor: string; textColor: string; isPreviewMode: boolean }>`
  flex: 1;
  position: relative;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ textColor }) => textColor};

  /* Ensure that all elements except PageNavigation have pointer-events disabled in preview mode */
  & > *:not(.allow-pointer-events) {
    pointer-events: ${({ isPreviewMode }) => (isPreviewMode ? 'none' : 'auto')};
  }

  /* Always allow pointer events for PageNavigation */
  .allow-pointer-events {
    pointer-events: auto;
  }
`;
const PaddedContent = styled.div<{ isPreviewMode: boolean }>`
  padding: 2vh; /* Reduce padding to a more reasonable value */
  border: ${({ isPreviewMode }) => (isPreviewMode ? 'none' : '2px dashed #ddd')};
  min-height: 75vh;
  box-sizing: border-box;

  /* Add flex properties to better center the content */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center elements horizontally */
`;


const NavigationContainer = styled.div`
  padding: 0; /* No padding for the navigation bar */
  position: relative;
  top: 0;
  z-index: 1000;
  width: 100%;
`;

const ElementContainer = styled.div<{ isPreviewMode: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1vh;
  background-color: transparent;
  border: ${({ isPreviewMode }) => (isPreviewMode ? 'none' : '1px solid #ddd')};
  border-radius: 5px;
  position: relative;  /* Ensure that child absolute elements are positioned relative to this container */
  width: 100%;
  box-sizing: border-box;
`;

const RemoveElementButton = styled.button`
  position: absolute;  /* Ensure it's positioned relative to the container */
  top: 5px;  /* Adjust to match your desired position */
  right: 5px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0.5vh 1vh;
  cursor: pointer;
  font-size: 1.2vh;
  z-index: 999; /* Ensure it stays above the content within the container */

  &:hover {
    background-color: #c0392b;
  }
`;

const DragHandle = styled.div`
  cursor: grab;
  padding: 0.5vh;
  margin-right: 1vh;
  background-color: #ddd;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2vh;
  height: 2vh;
  font-weight: bold;
  flex-shrink: 0;
`;

const TitleContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  margin: 0;
  padding: 0;
`;

const DescriptionContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  margin: 0;
  padding: 0;
  min-height: 10vh;
`;

const BreakContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  margin: 0;
  padding: 0;
`;

const DividerContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  margin: 0;
  padding: 0;
`;

const LayoutContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  margin: 0;
  padding: 0;
`;

const ThreePartExplainerContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  margin: 0;
  padding: 0;
  min-height: fit-content;
`;

const ColoredSpan = styled.span`
  color: #f39c12; /* Choose the color for the "a" and "e" */
`;

type TextAlign = 'left' | 'center' | 'right';

interface Element {
    id: string; 
    type: string;
    content?: string;
    url?: string;
    alignment?: TextAlign; 
    fontFamily?: string;
    size?: string;
    bold?: boolean;
    padding?: string;
    underline?: boolean;
    side?: 'left' | 'right';
    images?: string[];
    height?: number;
  }

  interface TwoPartLayoutElement extends Element {
    type: 'layout'; // Specialized type
    leftElement: Element | null;  // Allow null for left element
    rightElement: Element | null;
    leftWidth?: string;     // Optional width for the left side
    rightWidth?: string; 
  }

  type AllElements = Element | TwoPartLayoutElement;


const Dashboard: React.FC = () => {
    const { companyName, trades, serviceArea} = useContext(CompanyContext);
    const [elements, setElements] = useState<Element[]>(() => {
        const savedElements = localStorage.getItem('savedElements');
        return savedElements ? JSON.parse(savedElements) : [];
      });
  const [, setDraggedElement] = useState<Element | null>(null);
  const [backgroundColor, setBackgroundColor] = useState<string>('#ffffff');
  const [textColor, setTextColor] = useState<string>('#000000');
  const [isPreviewMode, setPreviewMode] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);

  const baseURL = process.env.NODE_ENV === 'production'
  ? 'https://trdsite-d122ae764dd4.herokuapp.com'
  : 'http://localhost:8080';

  useEffect(() => {
    localStorage.setItem('savedElements', JSON.stringify(elements));
   
  }, [elements]);


  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const elementType = e.dataTransfer.getData('elementType');
    if (elementType) {
      const newElement: Element = {
        id: uuidv4(),  // Generate a unique string ID using uuid
        type: elementType,
        images: elementType === 'gallery' ? ['', '', ''] : undefined,
        content: elementType === 'title' ? 'Title' : undefined,
        alignment: 'center',
        size: '16px',
        bold: false,
        underline: false
      };
      console.log('Dropped on ContentArea');
      setElements([...elements, newElement]);
    }
  };
  
  const allowDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = 'copy'; // or 'move' based on your needs
  };

  const handleDragStart = (element: Element, e: React.DragEvent<HTMLDivElement>) => {
    const elementWithContent = {
      ...element,
      content: elements.find((el) => el.id === element.id)?.content || element.content,
    };
    setDraggedElement(elementWithContent);
    e.dataTransfer.setData('element', JSON.stringify(elementWithContent));
  };

  const handleDropElement = (e: React.DragEvent<HTMLDivElement>, index: number) => {
    e.preventDefault();
    e.stopPropagation();
    const elementData = e.dataTransfer.getData('element');
    const elementType = e.dataTransfer.getData('elementType');
  
    if (elementData) {
      // Existing element being rearranged
      const draggedElement = JSON.parse(elementData);
      const updatedElements = elements.filter((el) => el.id !== draggedElement.id);
      updatedElements.splice(index, 0, draggedElement);
      setElements(updatedElements);
    } else if (elementType) {
      // New element from sidebar being added
      const newElement: Element = {
        id: uuidv4(),
        type: elementType,
        images: elementType === 'gallery' ? ['', '', ''] : undefined,
        content: elementType === 'title' ? 'Default Title' : undefined,
        alignment: 'center',
        size: '16px',
        bold: false,
        underline: false,
      };
      const updatedElements = [...elements];
      updatedElements.splice(index, 0, newElement);
      setElements(updatedElements);
    }
    console.log('Dropped on ElementContainer at index:', index);
    setDraggedElement(null);
  };
  

  const handleRemoveElement = (id: string) => {
    const updatedElements = elements.filter((element) => element.id !== id);
    setElements(updatedElements);
  };
  

  const handleDropIntoLayout = (
    side: 'left' | 'right',
    draggedElement: Element | null, // Use the correct type
    layoutId: string
  ) => {
    if (!draggedElement) return;
  
    // Remove the dragged element from its original position
    const updatedElements = elements.filter((el) => el.id !== draggedElement.id);
  
    // Add the dragged element into the specific layout
    const newElements = updatedElements.map((el) => {
      if (el.type === 'layout' && el.id === layoutId) {
        const layoutElement = el as TwoPartLayoutElement; // Ensure the element is a layout
  
        // Assign the dragged element to the correct side
        if (side === 'left') {
          layoutElement.leftElement = draggedElement;
        } else {
          layoutElement.rightElement = draggedElement;
        }
  
        return layoutElement; // Return the updated layout element
      }
  
      return el; // Return other elements unchanged
    });
  
    // Update the state with modified elements
    setElements(newElements);
    setDraggedElement(null); // Clear the dragged element after placing it
  };
  
  
  // Helper function to update an image in the array
const updateImageArray = (images: string[], index: number, newUrl: string) => {
  const updatedImages = [...images];
  updatedImages[index] = newUrl;
  return updatedImages;
};

// Helper function to remove an image from the array
const removeImageFromArray = (images: string[], index: number) => {
  return images.filter((_, i) => i !== index);
};


  const handleRemoveInnerElement = (side: 'left' | 'right') => {
    console.log("removing inner element")
    const updatedElements = elements.map((el) => {
      if (el.type === 'layout') {
        const layoutElement = el as TwoPartLayoutElement;
  
        if (side === 'left') {
          layoutElement.leftElement = null;  // Remove the left element
          console.log('Removing left element');
        } else {
          layoutElement.rightElement = null;  // Remove the right element
          console.log('Removing right element');
        }
  
        return layoutElement;
      }
      return el;
    });
  
    // Update state with the layout missing the removed inner element
    setElements(updatedElements);
  };
  

  const handleThemeChange = (newBackgroundColor: string) => {
    setBackgroundColor(newBackgroundColor);
    localStorage.setItem('backgroundColor', newBackgroundColor); // Save the background color in localStorage
  
    const brightness = calculateBrightness(newBackgroundColor);
    setTextColor(brightness < 128 ? '#ffffff' : '#000000');
  };

  useEffect(() => {
    const savedBackgroundColor = localStorage.getItem('backgroundColor');
    if (savedBackgroundColor) {
      setBackgroundColor(savedBackgroundColor);
      const brightness = calculateBrightness(savedBackgroundColor);
      setTextColor(brightness < 128 ? '#ffffff' : '#000000'); // Adjust text color on load
    }
  }, []);  

  const calculateBrightness = (color: string) => {
    const rgb = parseInt(color.slice(1), 16);
    const r = (rgb >> 16) & 255;
    const g = (rgb >> 8) & 255;
    const b = (rgb >> 0) & 255;
    return (r * 299 + g * 587 + b * 114) / 1000;
  };
  
  const handleUpdateElement = (id: string, updatedFields: Partial<Element>) => {
    setElements((prevElements) =>
      prevElements.map((element) =>
        element.id === id
          ? {
              ...element,
              ...updatedFields, // Spread updated fields including the updated images
            }
          : element
      )
    );
  };
  
  
  
  const handleElementCreate = async () => {
    setElements([]);
    let imageUrl = '';
    let descriptionText = '';
    let customSolutionsText = '';
    let tempImages: string[] = [];
  
    // Fetch the image URL if an image does not already exist
    const existingImageElement = elements.find(el => el.type === 'image');
    if (existingImageElement && existingImageElement.url) {
      imageUrl = existingImageElement.url;  // Use the existing image URL
    } else {
      try {
        const token = localStorage.getItem('trdtoken');
        if (!token) {
          console.error('No token found, user might not be authenticated');
          return;
        }
  
        // Fetch temp images
        const tempResponse = await fetch(`${baseURL}/upload/get-temp-images`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const tempData = await tempResponse.json();
  
        const cloudinaryResponse = await fetch(`${baseURL}/upload/get-images`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        let cloudinaryData = [];
        if (cloudinaryResponse.ok) {
          const cloudinaryJson = await cloudinaryResponse.json();
          cloudinaryData = cloudinaryJson.uploadedImages || [];
        }
        
        // Combine temp and Cloudinary images
        const combinedImages = [...tempData.storedImages, ...cloudinaryData];
        
        // Use the first three images for the ImageDisplayElement
        tempImages = combinedImages.slice(0, 3).map(image => 
          image.url.startsWith('/uploads') ? `${baseURL}${image.url}` : image.url
        );
        
        if (combinedImages.length > 0) {
          imageUrl = combinedImages[0].url.startsWith('/uploads')
            ? `${baseURL}${combinedImages[0].url}`  // Local image
            : combinedImages[0].url; // Cloudinary image
        }
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    }
  
   // Generate description text based on selected trades from context
   if (trades.includes('Landscaping')) {
    descriptionText += `At ${companyName}, we specialize in transforming outdoor spaces into beautiful, functional landscapes that reflect both the natural surroundings and your personal vision. From crafting lush gardens to building durable and aesthetically pleasing hardscapes such as patios, walkways, and retaining walls, we handle every aspect of your landscaping needs with precision and creativity.
  
    Our services include expert consultation, design, and implementation, ensuring that your outdoor spaces are not only beautiful but also practical and sustainable. Whether you're looking to create a peaceful garden retreat, a dynamic entertaining area, or a functional outdoor workspace, our team of professionals is here to bring your vision to life.
  
    We take pride in turning your outdoor dreams into reality, enhancing the value and appeal of your property throughout ${serviceArea}.\n`;
  }
  
  if (trades.includes('Building work')) {
    descriptionText += `At ${companyName}, we are committed to delivering building services that combine innovation with craftsmanship. Whether you're planning a renovation, an extension, or a new build, our experienced team works closely with you to ensure that every detail is executed to the highest standards.
  
    We handle all aspects of construction, from foundation to roofing, ensuring that each project is completed on time, within budget, and to your satisfaction. Our building services cater to both residential and commercial needs, offering structural integrity, energy efficiency, and aesthetic appeal.
  
    Proudly serving the ${serviceArea} area, we understand the importance of delivering solutions that meet both your functional and aesthetic needs, ensuring that every project not only meets but exceeds expectations.\n`;
  }
  
  if (trades.includes('Roofing')) {
    descriptionText += `At ${companyName}, we provide professional roofing services designed to protect and enhance your property. Whether you're in need of a completely new roof, repairs to fix leaks and damage, or routine maintenance, our team uses high-quality materials and skilled workmanship to ensure that your roof performs at its best.
  
    We offer a range of roofing options, from traditional shingles to modern, eco-friendly solutions, and we customize our services to fit your specific needs. With years of experience serving clients throughout ${serviceArea}, we understand the local climate and building requirements, ensuring that your roof stands strong through all conditions.
  
    Trust us to provide reliable, long-lasting roofing solutions that not only protect your property but also boost its curb appeal.\n`;
  }

  if (trades.includes('Ground work')) {
    descriptionText += `At ${companyName}, we provide expert ground work services that lay the foundation for any successful construction project. Our team is highly skilled in excavation, drainage systems, and site preparation, ensuring that your project begins with a solid and well-prepared foundation.
  
    From residential developments to commercial infrastructure, we offer comprehensive ground work services that are tailored to meet the specific needs of your site. Serving ${serviceArea}, we take pride in our attention to detail, safety standards, and efficiency, ensuring that your project gets off to the best possible start.\n`;
  }
  
  if (trades.includes('Roofing')) {
    descriptionText += `At ${companyName}, we provide professional roofing services designed to protect and enhance your property. Whether you're in need of a completely new roof, repairs to fix leaks and damage, or routine maintenance, our team uses high-quality materials and skilled workmanship to ensure that your roof performs at its best.
  
    We offer a range of roofing options, from traditional shingles to modern, eco-friendly solutions, and we customize our services to fit your specific needs. With years of experience serving clients throughout ${serviceArea}, we understand the local climate and building requirements, ensuring that your roof stands strong through all conditions.
  
    Trust us to provide reliable, long-lasting roofing solutions that not only protect your property but also boost its curb appeal.\n`;
  }
  
  if (trades.includes('Joinery')) {
    descriptionText += `At ${companyName}, our expert joinery services bring craftsmanship and precision to every project. From custom-built furniture and cabinetry to structural timber work, we provide a full range of joinery solutions tailored to meet the unique needs of each client.
  
    Whether you're looking to enhance the interior or exterior of your home with bespoke woodwork or need joinery services for a commercial project, we ensure every detail is crafted to perfection. Proudly serving ${serviceArea}, our team combines traditional skills with modern techniques to deliver exceptional results every time.\n`;
  }
  
  if (trades.includes('Carpet fitting')) {
    descriptionText += `At ${companyName}, we specialize in professional carpet fitting services that add comfort, style, and warmth to your living or work spaces. Whether you're looking to install carpets in a single room or an entire property, our team ensures that each installation is precise, seamless, and long-lasting.
  
    We offer a wide selection of high-quality carpets and underlay to suit your needs and budget. Serving ${serviceArea}, we take pride in our workmanship and attention to detail, ensuring your floors are fitted perfectly for maximum comfort and durability.\n`;
  }
  
  if (trades.includes('Painting')) {
    descriptionText += `At ${companyName}, we provide professional painting services that bring color, vibrancy, and fresh energy to your property. Whether it's an interior refresh or exterior painting, we use high-quality paints and finishes to ensure a flawless result that enhances the appearance and value of your property.
  
    From homes to commercial buildings, we offer comprehensive painting solutions tailored to meet your needs. With a focus on preparation and attention to detail, we ensure a smooth, even finish that lasts. Serving the ${serviceArea}, we are committed to delivering outstanding results that transform your space.\n`;
  }
  
  if (trades.includes('Plastering')) {
    descriptionText += `At ${companyName}, our professional plastering services provide a smooth, durable finish to your walls and ceilings, ready for painting or wallpapering. Whether you need plastering for new builds, renovations, or repairs, our team ensures a high-quality, consistent finish that enhances the overall appearance of your space.
  
    We offer a full range of plastering services, from traditional solid plastering to modern drywall solutions. Proudly serving ${serviceArea}, we combine expertise with top-quality materials to deliver a flawless result that stands the test of time.\n`;
  }
  
  if (trades.includes('Other')) {
    descriptionText += `At ${companyName}, we offer a variety of additional services tailored to meet your specific needs. Whether it's a unique project or a combination of different services, our team is equipped with the expertise and resources to handle any challenge.
  
    No matter what your project requires, we work closely with you to deliver results that exceed your expectations. Serving ${serviceArea}, we take pride in offering flexible, professional services that cater to your unique requirements.\n`;
  }
  
  
  // Default description if no specific trades are selected
  if (!descriptionText) {
    descriptionText = `At ${companyName}, we offer a wide array of professional services designed to meet your needs. From construction to maintenance, our skilled team brings years of experience and a dedication to quality to every project. Whether you require specialized services or general support, we are committed to delivering exceptional results that exceed expectations, serving clients throughout ${serviceArea}.\n`;
  }

  customSolutionsText = `At ${companyName}, we pride ourselves on our adaptability and ability to deliver custom solutions for even the most unique projects. Whether it's a one-off job or an ongoing service, we work closely with clients to ensure that their specific needs are met.

  Our team thrives on challenges and is committed to delivering high-quality results no matter the task. Serving the entire ${serviceArea}, we're here to provide flexible and tailored services that go beyond expectations.\n`;

  
  
  const newElements: AllElements[] = [
    {
      id: '1',
      type: 'break',
    },
    {
      id: '2',
      type: 'title',
      content: companyName || 'Default Title',
      alignment: 'center',
      size: '28px',
      bold: true,
    },
    {
      id: '3',
      type: 'image',
      url: imageUrl || '',
      size: '35',
    },
    {
      id: '4',
      type: 'title',
      content: 'About us',
      alignment: 'center',
      size: '18px',
      bold: true,
    },
    {
      id: '5',
      type: 'description',
      content: descriptionText,
      alignment: 'center',
      size: '18px',
      bold: false,
      padding: '30',
    },
    {
      id: '6',
      type: 'divider',
    },
    {
      id: '7',
      type: 'explainer',
    },
    {
      id: '8',
      type: 'divider',
    },
    {
      id: '9', // Two-part layout element
      type: 'layout',
      leftWidth: '66%',  // Set left width to 66%
      rightWidth: '34%',
      leftElement: {
        id: '10',
        type: 'description',
        content: customSolutionsText,
        alignment: 'right',
        size: '20',
        bold: false,
        padding: '5',
      },
      rightElement: {
        id: '11',
        type: 'imagedisplay',
        images: tempImages, // Set the first 3 temp images
        size: '50',
      },
    },
    {
      id: '12',
      type: 'break',
      height: 10, // Same custom height for the break element
    },
    {
      id: '13',
      type: 'gallery',
      images: tempImages.slice(0, 3), // Gallery element with 3 random images from the user's temp folder
    },
    {
      id: '14',
      type: 'break',
      height: 10, // Same custom height for the last break element
    },
  
  ];
  
    setElements(newElements); // This clears out existing elements and adds new ones
    localStorage.setItem('savedElements', JSON.stringify(newElements)); // Save the new elements
    console.log("Updated elements:", newElements);
  };
  
  const toggleModal = () => {
    setShowModal(!showModal);
    setPreviewMode(true)
  };

  const handleCloseModal = () => setShowModal(false); 

  return (
    <DashboardContainer>
     <Navbar>
        <div>
      Tr<ColoredSpan>a</ColoredSpan>d<ColoredSpan>e</ColoredSpan>Site
      </div>
        <NavbarButton onClick={() => setPreviewMode(!isPreviewMode)}>
          {isPreviewMode ? 'Exit Preview' : 'Preview'}
        </NavbarButton>
        <NavbarButton onClick={toggleModal}>
      Publish
    </NavbarButton>
      </Navbar>

      {showModal && <PublishModal setShowModal={handleCloseModal} />} 
      <MainContent>
      {!isPreviewMode && (
        <Sidebar>
        <Elements onThemeChange={handleThemeChange} 
         onElementCreate={handleElementCreate}
        allowedElements={['create','imagedisplay','gallery', 'info','title', 'image', 'description', 'layout', 'break', 'divider', 'explainer', 'theme']}/>
        </Sidebar>
        )}
       
       

        <ContentArea
            isPreviewMode={isPreviewMode}
            backgroundColor={backgroundColor}
            textColor={textColor}
            onDragOver={allowDrop}
            onDrop={handleDrop} // Ensures elements can be dropped here
            >
          <NavigationContainer>
          <PageNavigation className="allow-pointer-events" />

          </NavigationContainer>
          <PaddedContent isPreviewMode={isPreviewMode}>
            {elements.map((element, index) => (
              <ElementContainer 
              isPreviewMode={isPreviewMode}
              key={element.id}
              onDragOver={allowDrop}
              onDrop={(e) => handleDropElement(e, index)}
            >
                {!isPreviewMode && (
                <RemoveElementButton onClick={() => handleRemoveElement(element.id)}>
                  X
                </RemoveElementButton>
                )}
                {!isPreviewMode && (
                <DragHandle
                  draggable
                  onDragStart={(e) => handleDragStart(element, e)}
                >
                  &#x2630;
                </DragHandle>
                )}
                {element.type === 'title' && (
                  <TitleContainer>
                    <TitleElement
                        isPreviewMode={isPreviewMode}
                        content={typeof element.content === 'string' ? element.content : 'Enter Your Title'}
                        fontSize={element.size || "16px"}  // Check if `element.size` is valid
                        fontWeight={element.bold ? "bold" : "normal"}  // Ensure this is valid
                        fontFamily={element.fontFamily || "Arial, sans-serif"}  // Ensure default values are strings
                        textAlign={element.alignment as TextAlign || "left"}  // Ensure alignment is properly set
                        onUpdateContent={({ content, fontSize, fontWeight, fontFamily, textAlign }) => {
                          handleUpdateElement(element.id, {
                            content,  // Ensure content is a string here
                            size: fontSize,
                            bold: fontWeight === "bold",
                            alignment: textAlign as TextAlign,
                            fontFamily,
                          });
                        }}
                        onRemove={() => handleRemoveElement(element.id)}
                        />
                    </TitleContainer>
                )}
              {element.type === 'image' && (
                    <div>
                      <ImageElement
                      isPreviewMode={isPreviewMode} 
                        existingImage={element.url}
                        size={Number(element.size) || 50}  // Ensure size is a number
                        onImageSelect={(url: string) => handleUpdateElement(element.id, { url })}
                        onSizeChange={(newSize: number) => handleUpdateElement(element.id, { size: String(newSize) })}  // Convert size to string
                        onRemove={() => handleRemoveElement(element.id)}
                        />
                  </div>
                    )}


                  {element.type === 'imagedisplay' && (
                    
                    <ImageDisplayElement
                    isPreviewMode={isPreviewMode}
                    images={element.images || []}  // Ensure it's an array, fallback to an empty array if undefined
                    size={Number(element.size) || 50}  // Pass the size, defaulting to 50 if not set
                    onSizeChange={(newSize) => handleUpdateElement(element.id, { size: String(newSize) })}  // Update the size in the element
                    onImageSelect={(updatedImages: string[]) =>
                      handleUpdateElement(element.id, { images: updatedImages }) // Updated to handle array of selected images
                    }
                    onRemoveElement={() => handleRemoveElement(element.id)}  // Pass the removal function here
                  />
                  )}


                    {element.type === 'gallery' && (
                    <div>
                     <CenteredWrapper>
                      <ImagePreviewComponent
                       images={element.images || []}
                        isPreviewMode={isPreviewMode}
                        onImageSelect={(index: number, url: string) => handleUpdateElement(element.id, { images: updateImageArray(element.images ?? [], index, url) })}
                        onRemove={(index: number) => handleUpdateElement(element.id, { images: removeImageFromArray(element.images ?? [], index) })}
                        onRemoveElement={() => handleRemoveElement(element.id)}
                      />
                    </CenteredWrapper>

                  </div>
                    )}
                {element.type === 'description' && (
                  <DescriptionContainer>
                   <DescriptionElement
                   isPreviewMode={isPreviewMode} 
                      content={element.content || "Enter Your Title"}
                      fontSize={element.size || "16px"}
                      fontWeight={element.bold ? 'bold' : 'normal'}  // Pass font weight
                      fontFamily="Arial, sans-serif" // Example font family, adjust as needed
                      textAlign={element.alignment || 'left'}  // Pass text alignment
                      padding={element.padding || '20vh'}  // Pass the padding value or default to '2vh'
                      onPaddingChange={(newPadding) => {
                        handleUpdateElement(element.id, {
                          padding: newPadding,  // Update the padding in the state
                        });
                      }}
                      onUpdateContent={({ content, fontSize, fontWeight, fontFamily, textAlign }) => {
                        handleUpdateElement(element.id, {
                          content,
                          size: fontSize,
                          bold: fontWeight === 'bold',
                          alignment: textAlign,
                          fontFamily: fontFamily,
                        });
                      }}
                      onRemove={() => handleRemoveElement(element.id)}
                    />
                  </DescriptionContainer>
                )}
               {element.type === 'layout' && (
                        <LayoutContainer>
                            <TwoPartLayout
                                    layoutId={String(element.id)} // Ensure the layoutId is a string
                                    isPreviewMode={isPreviewMode}
                                    onRemove={() => handleRemoveElement(element.id)}
                                    onDropLeft={(draggedElement) => handleDropIntoLayout('left', draggedElement, String(element.id))} // Pass layoutId, remove event
                                    onDropRight={(draggedElement) => handleDropIntoLayout('right', draggedElement, String(element.id))} // Pass layoutId, remove event
                                    leftElement={(element as TwoPartLayoutElement).leftElement}
                                    rightElement={(element as TwoPartLayoutElement).rightElement}
                                    leftWidth={(element as TwoPartLayoutElement).leftWidth || '50%'}
                                    rightWidth={(element as TwoPartLayoutElement).rightWidth || '50%'}
                                    onRemoveInnerElement={handleRemoveInnerElement}
                                />
                        </LayoutContainer>
                        )}

{element.type === 'break' && (
  <BreakContainer>
    <BreakElement
      isPreviewMode={isPreviewMode}
      height={element.height || 5} // Pass the height from element or default to 5
      onHeightChange={(newHeight) => handleUpdateElement(element.id, { height: newHeight })} // Handle height change
      onRemove={() => handleRemoveElement(element.id)}
    />
  </BreakContainer>
)}

                 {element.type === 'explainer' && (
                  <ThreePartExplainerContainer>
                    <ThreePartExplainerElement
                    isPreviewMode={isPreviewMode} 
                      onRemove={() => handleRemoveElement(element.id)}
                    />
                  </ThreePartExplainerContainer>
                )}
                {element.type === 'divider' && (
                  <DividerContainer>
                    <DividerElement
                    isPreviewMode={isPreviewMode} 
                      onRemove={() => handleRemoveElement(element.id)}
                    />
                  </DividerContainer>
                )}
                {element.type === 'theme' && (
             
                    <ThemeElement onChangeTheme={handleThemeChange} />
                 
                )}
              </ElementContainer>
            ))}
          </PaddedContent>
          <PageFooter />
        </ContentArea>
      </MainContent>
    </DashboardContainer>
  );
};

export default Dashboard;
