import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { FaAlignLeft, FaAlignCenter, FaAlignRight } from 'react-icons/fa';

type TextAlign = 'left' | 'center' | 'right';

const TitleContainer = styled.div<{ fontSize: string; fontWeight: string; textAlign: TextAlign; fontFamily: string }>`
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  text-align: ${({ textAlign }) => textAlign};
  font-family: ${({ fontFamily }) => fontFamily};
  margin: 0;
  padding: 0;
  position: relative; /* Added for positioning remove button */
`;

const EditableInput = styled.input<{ textAlign: TextAlign; fontSize: string; fontWeight: string; fontFamily: string }>`
  width: 100%;
  border: none;
  background-color: transparent;
  outline: none;
  text-align: ${({ textAlign }) => textAlign}; /* Apply text alignment while editing */
  font-size: ${({ fontSize }) => fontSize}; /* Apply font size */
  font-weight: ${({ fontWeight }) => fontWeight}; /* Apply font weight */
  font-family: ${({ fontFamily }) => fontFamily}; /* Apply font family */
`;

const ControlContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1vh;
`;

const ControlButton = styled.button<{ active?: boolean }>`
  background-color: ${({ active }) => (active ? '#ddd' : 'transparent')};
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 0.5vh;
  cursor: pointer;
  margin: 0 0.5vh;
  font-size: 1.5vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #f0f0f0;
  }

  svg {
    font-size: 1.5vh;
  }
`;

const Select = styled.select`
  margin: 0 0.5vh;
  padding: 0.5vh;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1.5vh;
  cursor: pointer;
`;

interface TitleElementProps {
  isPreviewMode: boolean; 
  content: string;
  fontSize?: string;
  fontWeight?: string;
  fontFamily?: string;
  textAlign?: TextAlign;
  onUpdateContent: (props: {
    content: string;
    fontSize: string;
    fontWeight: string;
    fontFamily: string;
    textAlign: TextAlign;
  }) => void;
  onRemove: () => void;
}

const TitleElement: React.FC<TitleElementProps> = ({
  content = 'Enter Your Title',
  fontSize = '2.5vh',
  fontWeight = 'bold',
  fontFamily = 'Arial, sans-serif',
  textAlign = 'left',
  isPreviewMode,  // Add isPreviewMode prop
  onUpdateContent,
  onRemove,
}) => {
  const [title, setTitle] = useState<string>(content);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [showControlPanel, setShowControlPanel] = useState<boolean>(false);
  const [alignment, setAlignment] = useState<TextAlign>(textAlign);
  const [size, setSize] = useState<string>(fontSize);
  const [font, setFont] = useState<string>(fontFamily);
  const titleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (titleRef.current && !titleRef.current.contains(event.target as Node)) {
        setShowControlPanel(false); // Close the control panel when clicking outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

  
    setTitle(e.target.value); // Update only the string content
    onUpdateContent({
      content: e.target.value, // Pass only the string, avoid nesting
      fontSize: size,
      fontWeight: fontWeight,
      fontFamily: font,
      textAlign: alignment,
    });
  }; 

  const handleBlur = () => {
    setIsEditing(false);
  };

  const handleAlignmentChange = (align: TextAlign) => {
    setAlignment(align);
    onUpdateContent({
      content: title,
      fontSize: size,
      fontWeight,
      fontFamily: font,
      textAlign: align,
    });
  };

  const handleFontSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newSize = e.target.value;
    setSize(newSize);
    onUpdateContent({
      content: title,
      fontSize: newSize,
      fontWeight,
      fontFamily: font,
      textAlign: alignment,
    });
  };

  const handleFontFamilyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newFont = e.target.value;
    setFont(newFont);
    onUpdateContent({
      content: title,
      fontSize: size,
      fontWeight,
      fontFamily: newFont,
      textAlign: alignment,
    });
  };

  const handleEditClick = () => {
    setIsEditing(true);
    setShowControlPanel(true);
  };

  return (
    <TitleContainer
      ref={titleRef}
      fontSize={size}
      fontWeight={fontWeight}
      textAlign={alignment}
      fontFamily={font}
    >
      {showControlPanel && (
        <ControlContainer>
          <ControlButton
            active={alignment === 'left'}
            onClick={() => handleAlignmentChange('left')}
          >
            <FaAlignLeft />
          </ControlButton>
          <ControlButton
            active={alignment === 'center'}
            onClick={() => handleAlignmentChange('center')}
          >
            <FaAlignCenter />
          </ControlButton>
          <ControlButton
            active={alignment === 'right'}
            onClick={() => handleAlignmentChange('right')}
          >
            <FaAlignRight />
          </ControlButton>
          <Select value={size} onChange={handleFontSizeChange}>
            <option value="20px">20</option>
            <option value="22px">22</option>
            <option value="24px">24</option>
            <option value="26px">26</option>
            <option value="28px">28</option>
          </Select>
          <Select value={font} onChange={handleFontFamilyChange}>
          <option value="Arial, sans-serif">Arial</option>
          <option value="'Times New Roman', serif">Times New Roman</option>
          <option value="'Verdana', sans-serif">Verdana</option>
          <option value="'Trebuchet MS', sans-serif">Trebuchet MS</option>
          <option value="'Lucida Sans', 'Lucida Grande', 'Lucida Sans Unicode', sans-serif">Lucida Sans</option>
          <option value="'Tahoma', sans-serif">Tahoma</option>
          <option value="'Gill Sans', sans-serif">Gill Sans</option>
          <option value="'Palatino', 'Palatino Linotype', 'Book Antiqua', serif">Palatino</option>
          <option value="'Ubuntu', sans-serif">Ubuntu</option>

          </Select>
        </ControlContainer>
      )}

      {isEditing ? (
        <EditableInput
          type="text"
          value={title}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
          textAlign={alignment}
          fontSize={size}
          fontWeight={fontWeight}
          fontFamily={font}
        />
      ) : (
        <h2 onClick={handleEditClick} style={{ textAlign: alignment, fontSize: size, fontFamily: font }}>
          {title}
        </h2>
      )}
    </TitleContainer>
  );
};

export default TitleElement;
