import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { FaHeading, FaPlus, FaImage, FaTextHeight, FaColumns, FaMinus, FaArrowsAltH, FaPalette, FaInfoCircle } from 'react-icons/fa';
import ThemeElement from './ThemeElement';
import InfoElement from './InfoElement';
import { CompanyContext } from './context/CompanyContext';
import LazyLoad from 'react-lazyload';

const ElementsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Create 2 equal columns */
  gap: 1vh; /* Add some space between elements */
  
  @media (orientation: portrait) {
    grid-template-columns: repeat(3, 1fr); /* Use 3 columns in portrait mode for better use of space */
  }
`;

const ElementItem = styled.div`
  background-color: #fff;
  padding: 1.5vh;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%; /* Full width within the grid */
  font-size: 1.3vh; /* Slightly smaller font */

  &:hover {
    background-color: #f0f0f0;
  }

  svg {
    font-size: 2.5vh; /* Adjust icon size */
    margin-bottom: 0.5vh;
  }

  @media (orientation: portrait) {
    width: 100%; /* Ensure full width in portrait mode */
  }
`;


const UploadButton = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  padding: 1vh;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.5vh;
  width: 100%;
  margin-top: 1vh;

  &:hover {
    background-color: #2980b9;
  }
`;

const Placeholder = styled.div`
  width: 22vh;
  height: 22vh;
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5vh;
  color: #888;
`;


const ElementText = styled.div`
  font-size: 1.5vh;
`;

const SidebarContainer = styled.div`
  position: relative;
`;

const ThemePopupContainer = styled.div`
  position: absolute;
  top: 0;
  left: 22vh;
  padding: 2vh;
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 5px;
`;


const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  background-color: white;
  padding: 2vh;
  border-radius: 5px;
  width: 80vh;
  height: 70vh;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: 9999;
   &.modal-scroll {  // Assign a class to this container
    position: relative;
  }
`;

const ModalHeader = styled.h3`
  margin: 0;
  margin-bottom: 2vh;
  text-align: center;
`;

const ModalButton = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  padding: 1vh 2vh;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 2vh;
  width: 100%;

  &:hover {
    background-color: #2980b9;
  }
`;

const ImageGallery = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2vh;
  max-height: 50vh;
  overflow-y: auto;
   &.modal-scroll {  // Assign a class to this container
    position: relative;
  }
`;

const ImageItem = styled.img`
  width: 22vh;
  height: 22vh;
  object-fit: cover;
  cursor: pointer;
  display: block;
`;

const PreviewImage = styled.img`
  width: 10vh;
  height: 10vh;
  margin: 1vh;
  object-fit: cover;
`;

const ImageWrapper = styled.div`
  position: relative;
`;

const Checkbox = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 2vh;
  height: 2vh;
`;

const StorageInfo = styled.div`
  font-size: 1.2vh;
  color: #333;
  margin-bottom: 2vh;
`;

interface Image {
  url?: string;  // Cloudinary images
  path?: string; // Locally stored images
  size?: number; // Add the size property here
}

interface ElementsProps {
  onThemeChange: (color: string) => void;
  allowedElements?: string[];  // Add a prop to control which elements are allowed
  onLayoutSelect?: (layoutNumber: number) => void; 
  onElementCreate: (newElements: any[]) => void;
  onAddImages?: () => void;
}

const Elements: React.FC<ElementsProps> = ({
  onThemeChange,
  onElementCreate,
  allowedElements = [],
  onLayoutSelect = () => {},
  onAddImages,
}) => {
  const [galleryImages, setGalleryImages] = useState<(string | Image)[]>([]);
  const [showThemePopup, setShowThemePopup] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [selectedForDeletion, setSelectedForDeletion] = useState<string[]>([]);
  const [, setUserId] = useState<string | null>(null);
  const [imagesFetched, setImagesFetched] = useState(false); 
  const [showInfoModal, setShowInfoModal] = useState(false);
  const { companyName } = useContext(CompanyContext); 
  const [totalSize, setTotalSize] = useState<number>(0);
  const [imageSizeMap] = useState<Record<string, number>>({});

  const baseURL = process.env.NODE_ENV === 'production'
  ? 'https://trdsite-d122ae764dd4.herokuapp.com'
  : 'http://localhost:8080';

  const MAX_STORAGE_LIMIT = 1 * 1024 * 1024 * 1024; // 1 GB in bytes


  const defaultElements = [
    {
      id: 1,
      type: 'title',
      content: companyName || 'Default Company Name',
      alignment: 'center',
      size: '24px',
      bold: true,
    },
    {
      id: 2,
      type: 'break',
    },
    {
      id: 4,
      type: 'explainer',
    },
  ];

  // Handle "Create" button click
  const handleCreatePage = () => {
    onElementCreate(defaultElements); // Trigger the creation of default elements
  };

  useEffect(() => {
    if (!companyName) {
      setShowInfoModal(true);
    }
  }, [companyName]);

  const handleInfoElementClick = () => {
    console.log("open")
    setShowInfoModal(true); // Open the modal when the Info element is clicked
  };

  const allowedSet = new Set(allowedElements);

  useEffect(() => {
    const storedUserId = localStorage.getItem('trduserId');
    if (storedUserId) {
      setUserId(storedUserId);
    } else {
      console.error('User ID not found in localStorage');
    }
  }, []);

  const fetchTempImages = async () => {
  const token = localStorage.getItem('trdtoken');
  if (!token) {
    console.error('No token found, user might not be authenticated');
    return;
  }

  try {
    // Fetch temporary images from local storage
    const tempResponse = await fetch(`${baseURL}/upload/get-temp-images`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!tempResponse.ok) {
      const tempErrorResponse = await tempResponse.json();
      console.error('Error fetching temp images:', tempErrorResponse);
      return;
    }

    const tempData = await tempResponse.json();

    // Fetch uploaded images from Cloudinary
    const uploadedResponse = await fetch(`${baseURL}/upload/get-images`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    let uploadedData = [];
    if (uploadedResponse.ok) {
      const uploadedJson = await uploadedResponse.json();
      uploadedData = uploadedJson.uploadedImages || [];
    }

    // Combine both temporary and uploaded images
    const combinedImages = [...tempData.storedImages, ...uploadedData];

    // Recalculate the total storage size of all images
    let totalStorageSize = 0;

    combinedImages.forEach((image, index) => {
      const imageUrl = typeof image === 'string' ? image : image?.url || '';
      const imageSize = image.size || 512000; // Use actual size or fallback to 512 KB

      // Log the image URL and its size
      console.log(`Image ${index + 1}: URL = ${imageUrl}, Size = ${imageSize} bytes`);

      imageSizeMap[imageUrl] = imageSize; // Populate size map
      totalStorageSize += imageSize; // Accumulate the total size
    });

    // Set the gallery images and the total storage size
    setGalleryImages(combinedImages);
    setTotalSize(totalStorageSize); // Set the total size for storage display
    setImagesFetched(true); // Set that the images have been fetched

  } catch (error) {
    console.error('Error fetching images:', error);
  }
};

  const handleSelectImage = (files: FileList | null) => {
    if (files) {
      const newImages = Array.from(files);
      const newSize = newImages.reduce((acc, file) => {
        const imageUrl = `/uploads/temp/${file.name}`;  // Store the full path
        imageSizeMap[imageUrl] = file.size;  // Use full image URL as key
        return acc + file.size;
      }, 0);

      // This ensures that you're only updating the size for selected images, not uploading them twice
      const updatedTotalSize = totalSize + newSize;
  
      if (updatedTotalSize <= MAX_STORAGE_LIMIT) {
        setSelectedImages((prevImages) => [...prevImages, ...newImages]);
        setTotalSize(updatedTotalSize);
      } else {
        alert("You have exceeded the storage limit of 10GB.");
      }
    }
  };
  
  const formatBytesInMB = (bytes: number, decimals = 2) => {
    const MB = 1024 * 1024; // 1 MB = 1024 * 1024 bytes
    const sizeInMB = (bytes / MB).toFixed(decimals);
    return `${sizeInMB} MB`;
  };
  
  

  const handleUploadImagesLocally = async () => {
    if (!selectedImages.length) {
      console.error('No images selected');
      return;
    }
  
    const token = localStorage.getItem('trdtoken');
    if (!token) {
      console.error('No token found, user might not be authenticated');
      return;
    }
  
    const formData = new FormData();
    selectedImages.forEach((image) => {
      formData.append('images', image);
      console.log(`Uploading image: ${image.name}, size: ${image.size} bytes`);
    });
  
    try {
      const response = await fetch(`${baseURL}/upload/upload-temp`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        const errorResponse = await response.json();
        console.error('Error uploading images locally:', errorResponse);
        return;
      }
  
      const data = await response.json();
      setGalleryImages((prev) => [...prev, ...data.storedImages]);
  
      // Store relative URLs in imageSizeMap
      data.storedImages.forEach((image: Image) => {
        if (image.url) {
          const relativeUrl = image.url.startsWith('/uploads') ? image.url : `/uploads/temp/${image.url.split('/').pop()}`;
          imageSizeMap[relativeUrl] = image.size || 0;
          console.log(`Image added to map: ${relativeUrl}, size: ${imageSizeMap[relativeUrl]} bytes`);
        } else {
          console.warn('Image URL is undefined, skipping this image.');
        }
      });
  
      setSelectedImages([]);  // Clear selected images
      console.log("Upload complete. Storage usage remains the same as it was calculated when images were selected.");
    } catch (error) {
      console.error('Error saving images locally:', error);
    }
  };
  
  
  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, elementType: string) => {
    e.dataTransfer.setData('elementType', elementType);
  };
  

  const handleOpenImageModal = () => {
    if (!imagesFetched) {
      fetchTempImages(); // Fetch images only once before opening modal
    }
    setShowImageModal(true);
  };

  const handleThemeClick = () => {
    setShowThemePopup(!showThemePopup);
  };

  const deleteSelectedImages = async (selectedForDeletion: string[], setGalleryImages: React.Dispatch<React.SetStateAction<(string | Image)[]>>, setSelectedForDeletion: React.Dispatch<React.SetStateAction<string[]>>) => {
    const token = localStorage.getItem('trdtoken');
    if (!token) {
      console.error('No token found, user might not be authenticated');
      return;
    }
  
    try {
      // Separate Cloudinary images and local temp images
      const cloudinaryImages = selectedForDeletion.filter((imageUrl) => imageUrl.startsWith('http'));
      const tempImages = selectedForDeletion.filter((imageUrl) => imageUrl.startsWith('/uploads/temp'));
  
      // Delete Cloudinary images
      if (cloudinaryImages.length > 0) {
        await Promise.all(
          cloudinaryImages.map(async (imageUrl) => {
            const urlParts = imageUrl.split('/upload/');
            if (urlParts.length < 2) {
              console.error('Invalid image URL format:', imageUrl);
              return;
            }
  
            const publicIdWithVersion = urlParts[1];
            const publicId = publicIdWithVersion.replace(/v[0-9]+\/|(\.[^/.]+$)/g, '');
  
            if (!publicId) {
              console.error('Failed to extract publicId from image URL:', imageUrl);
              return;
            }
  
            const response = await fetch(`${baseURL}/upload/delete-image`, {
              method: 'DELETE',
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ publicId }),
            });
  
            if (!response.ok) {
              const errorResponse = await response.json();
              console.error('Error deleting image:', errorResponse);
              return;
            }
  
            console.log('Image deleted successfully:', publicId);
          })
        );
      }
  
      // Delete local temp images
      if (tempImages.length > 0) {
        const filenames = tempImages.map((imageUrl) => imageUrl.split('/uploads/temp/')[1]); // Extract the filenames
  
        const response = await fetch(`${baseURL}/upload/delete-temp-images`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ filenames }), // Send filenames for deletion
        });
  
        if (!response.ok) {
          const errorResponse = await response.json();
          console.error('Error deleting temp images:', errorResponse);
          return;
        }
  
        console.log('Temp images deleted successfully');
      }
  
      // Update the gallery by removing deleted images
      setGalleryImages((prevImages) =>
        prevImages.filter((img) => {
          const imgUrl = typeof img === 'string' ? img : img.url || '';
          return !selectedForDeletion.includes(imgUrl);
        })
      );
  
      setSelectedForDeletion([]); // Clear selected images after deletion
    } catch (error) {
      console.error('Error deleting images:', error);
    }
  };

  useEffect(() => {
    let newTotalSize = 0;

    galleryImages.forEach((image) => {
      const imageUrl = typeof image === 'string' ? image : image?.url || '';
      const relativeUrl = imageUrl.startsWith('/uploads') ? imageUrl : `/uploads/temp/${imageUrl.split('/').pop()}`;
      const imageSize = imageSizeMap[relativeUrl] || 0;
      newTotalSize += imageSize;
    });

    console.log(`MAX_STORAGE_LIMIT: ${MAX_STORAGE_LIMIT}`);  // Ensure this is 10737418240 bytes
    console.log(`Total Size Used: ${newTotalSize} bytes`);  // Should show actual usage
    console.log(`Storage Remaining (in bytes): ${MAX_STORAGE_LIMIT - newTotalSize}`);  // Subtraction check

    setTotalSize(newTotalSize);
}, [galleryImages, MAX_STORAGE_LIMIT, imageSizeMap]);


  

  return (
    <SidebarContainer>
      <ElementsContainer>
      {(!allowedElements.length || allowedSet.has('create')) && (
      <ElementItem onClick={handleCreatePage}>
          <FaPlus />
          <ElementText>Create Page</ElementText>
        </ElementItem>
      )}
      {(!allowedElements.length || allowedSet.has('info')) && (
        <ElementItem onClick={handleInfoElementClick}>
          <FaInfoCircle />
          <ElementText>Information</ElementText>
        </ElementItem>
      )}

        {(!allowedElements.length || allowedSet.has('title')) && (
          <ElementItem draggable onDragStart={(e) => handleDragStart(e, 'title')}>
            <FaHeading />
            <ElementText>Title</ElementText>
          </ElementItem>
        )}

        {(!allowedElements.length || allowedSet.has('image')) && (
          <ElementItem draggable onDragStart={(e) => handleDragStart(e, 'image')}>
            <FaImage />
            <ElementText>Image</ElementText>
            <UploadButton onClick={handleOpenImageModal}>Upload</UploadButton>
          </ElementItem>
        )}

{(!allowedElements.length || allowedSet.has('imagedisplay')) && (
          <ElementItem draggable onDragStart={(e) => handleDragStart(e, 'imagedisplay')}>
            <FaImage />
            <ElementText>Image Display</ElementText>
          </ElementItem>
        )}

        {(!allowedElements.length || allowedSet.has('gallery')) && (
                  <ElementItem draggable onDragStart={(e) => handleDragStart(e, 'gallery')}>
                    <FaImage />
                    <ElementText>Gallery</ElementText>
                  </ElementItem>
                )}

        {(!allowedElements.length || allowedSet.has('description')) && (
          <ElementItem draggable onDragStart={(e) => handleDragStart(e, 'description')}>
            <FaTextHeight />
            <ElementText>Text Box</ElementText>
          </ElementItem>
        )}

        {(!allowedElements.length || allowedSet.has('layout')) && (
          <ElementItem draggable onDragStart={(e) => handleDragStart(e, 'layout')}>
            <FaColumns />
            <ElementText>Split Layout</ElementText>
          </ElementItem>
        )}

        {(!allowedElements.length || allowedSet.has('break')) && (
          <ElementItem draggable onDragStart={(e) => handleDragStart(e, 'break')}>
            <FaMinus />
            <ElementText>Break</ElementText>
          </ElementItem>
        )}

        {(!allowedElements.length || allowedSet.has('divider')) && (
          <ElementItem draggable onDragStart={(e) => handleDragStart(e, 'divider')}>
            <FaArrowsAltH />
            <ElementText>Divider</ElementText>
          </ElementItem>
        )}

        {(!allowedElements.length || allowedSet.has('explainer')) && (
          <ElementItem draggable onDragStart={(e) => handleDragStart(e, 'explainer')}>
            <FaInfoCircle />
            <ElementText>Three-Part Explainer</ElementText>
          </ElementItem>
        )}

        {(!allowedElements.length || allowedSet.has('theme')) && (
          <ElementItem onClick={handleThemeClick}>
            <FaPalette />
            <ElementText>Theme</ElementText>
          </ElementItem>
        )}

{allowedElements.includes('layout1') && (
        <ElementItem onClick={() => onLayoutSelect(1)}>
          <ElementText>Layout 1</ElementText>
        </ElementItem>
      )}
         {allowedElements.includes('layout2') && (
        <ElementItem onClick={() => onLayoutSelect(2)}>
          <ElementText>Layout 2</ElementText>
        </ElementItem>
      )}
      {allowedElements.includes('layout3') && (
        <ElementItem onClick={() => onLayoutSelect(3)}>
          <ElementText>Layout 3</ElementText>
        </ElementItem>
      )}
      {allowedElements.includes('layout4') && (
        <ElementItem onClick={() => onLayoutSelect(4)}>
          <ElementText>Layout 4</ElementText>
        </ElementItem>
      )}
       {allowedElements.includes('addimages') && (
        <ElementItem onClick={onAddImages}>
          <ElementText>Add Images</ElementText>
        </ElementItem>
      )}

      </ElementsContainer>

      {showThemePopup && (
        <ThemePopupContainer>
          <ThemeElement onChangeTheme={onThemeChange} />
        </ThemePopupContainer>
      )}

{showInfoModal && (
  <InfoElement setShowModal={setShowInfoModal} />  // Pass setShowModal as a prop
)}

      {showImageModal && (
        <ModalBackdrop onClick={() => setShowImageModal(false)}>
          <ModalContainer onClick={(e) => e.stopPropagation()}>
            <ModalHeader>Select and Upload Images</ModalHeader>
            <input type="file" accept="image/*" multiple onChange={(e) => handleSelectImage(e.target.files)} />
            <div>
              {selectedImages.map((image, index) => (
                <PreviewImage key={index} src={URL.createObjectURL(image)} alt={`Preview ${index}`} />
              ))}
            </div>

            <ModalButton onClick={handleUploadImagesLocally}>Upload</ModalButton>

            <ImageGallery>
  {galleryImages.map((image, index) => {
    const imageUrl = typeof image === 'string' ? image : image?.url;
    if (!imageUrl) return null;

    const fullImageUrl = imageUrl.startsWith('/uploads') ? `${baseURL}${imageUrl}` : imageUrl;

    return (
      <ImageWrapper key={index}>
        <LazyLoad
  key={index}
  height={200}  
  offset={300}  
  scrollContainer=".modal-scroll"  // Reference your modal's scroll container class
  placeholder={<Placeholder>Loading...</Placeholder>}
>
  <ImageItem src={fullImageUrl} alt={`Uploaded ${index}`} />
</LazyLoad>

        <Checkbox
          type="checkbox"
          checked={selectedForDeletion.includes(imageUrl)}
          onChange={() =>
            setSelectedForDeletion((prev) =>
              prev.includes(imageUrl)
                ? prev.filter((img) => img !== imageUrl)
                : [...prev, imageUrl]
            )
          }
        />
      </ImageWrapper>
    );
  })}
</ImageGallery>


            {selectedForDeletion.length > 0 && (
              <ModalButton
              onClick={() =>
                deleteSelectedImages(selectedForDeletion, setGalleryImages, setSelectedForDeletion,)
              }
            >
              Delete Selected
            </ModalButton>
            
            )}

            <ModalButton onClick={() => setShowImageModal(false)}>Close</ModalButton>
            <StorageInfo>
        {/* Show the used storage and remaining storage */}
        <p>Storage Used: {formatBytesInMB(totalSize)}</p>
        <p>Storage Remaining: {formatBytesInMB(MAX_STORAGE_LIMIT - totalSize)}</p>

      </StorageInfo>
          </ModalContainer>
        </ModalBackdrop>
      )}

    </SidebarContainer>
  );
};

export default Elements;
