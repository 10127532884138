import React, { useState } from 'react';
import styled from 'styled-components';

interface Layout3Props {
  images: string[];
  onRemoveImage: (index: number) => void;
  isPreviewMode: boolean;
  onImageClick?: (imageSrc: string) => void;
  onImagesReorder: (newImages: string[]) => void; // Added prop to reorder images
}

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 25vh;
  gap: 1vh;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-auto-rows: 30vh;
  }
`;

const ImageWrapper = styled.div<{ gridColumn?: string; gridRow?: string; isSelected: boolean }>`
  position: relative;
  grid-column: ${(props) => props.gridColumn || 'span 1'};
  grid-row: ${(props) => props.gridRow || 'span 1'};
  overflow: hidden;
  box-shadow: ${({ isSelected }) => (isSelected ? '0 0 0 3px blue' : 'none')}; /* Highlight selected image */

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 1vh;
  right: 1vh;
  background-color: rgba(255, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 2vh;
  height: 2vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5vh;
`;

const Layout3: React.FC<Layout3Props> = ({
  images,
  onRemoveImage,
  isPreviewMode,
  onImageClick,
  onImagesReorder,
}) => {
  const [firstSelectedIndex, setFirstSelectedIndex] = useState<number | null>(null); // Track first selected image

  // Handle image click for swapping logic
  const handleImageClick = (index: number) => {
    if (isPreviewMode) {
      if (onImageClick) {
        onImageClick(images[index]);
      }
    } else {
      if (firstSelectedIndex === null) {
        setFirstSelectedIndex(index); // Select the first image
      } else {
        const newImages = [...images];
        const temp = newImages[firstSelectedIndex];
        newImages[firstSelectedIndex] = newImages[index];
        newImages[index] = temp;
        setFirstSelectedIndex(null);
        onImagesReorder(newImages); // Reorder images after swap
      }
    }
  };

  // Define grid positions for each image
  const imagePositions: {
    gridColumn: string;
    gridRow: string;
    imageIndex: number;
  }[] = [];

  let currentRow = 1;
  let groupIndex = 0;
  let imageIndex = 0;

  while (imageIndex < images.length) {
    const isEvenGroup = groupIndex % 2 === 0;

    // Assign positions for the larger image
    if (imageIndex < images.length) {
      imagePositions.push({
        gridColumn: isEvenGroup ? '1 / 2' : '2 / 3',
        gridRow: `${currentRow} / span 2`,
        imageIndex,
      });
      imageIndex++;
    }

    // Assign positions for the two smaller images
    if (imageIndex < images.length) {
      imagePositions.push({
        gridColumn: isEvenGroup ? '2 / 3' : '1 / 2',
        gridRow: `${currentRow} / ${currentRow + 1}`,
        imageIndex,
      });
      imageIndex++;
    }

    if (imageIndex < images.length) {
      imagePositions.push({
        gridColumn: isEvenGroup ? '2 / 3' : '1 / 2',
        gridRow: `${currentRow + 1} / ${currentRow + 2}`,
        imageIndex,
      });
      imageIndex++;
    }

    currentRow += 2;
    groupIndex++;
  }

  const placeholderImage = '/images/placeholder.png';
  
  return (
    <GridContainer>
      {imagePositions.map((pos) => (
        <ImageWrapper
          key={pos.imageIndex}
          gridColumn={pos.gridColumn}
          gridRow={pos.gridRow}
          isSelected={firstSelectedIndex === pos.imageIndex} // Highlight selected image
          onClick={() => handleImageClick(pos.imageIndex)} // Handle image click
        >
          <img  onError={(e) => {
              (e.target as HTMLImageElement).src = placeholderImage; // Set placeholder image if the image fails to load
            }}
            src={images[pos.imageIndex]}
            alt={`Gallery item ${pos.imageIndex + 1}`}
            style={{ cursor: isPreviewMode ? 'pointer' : 'default' }}
          />
          {!isPreviewMode && (
            <RemoveButton
            onClick={(e) => {
              e.stopPropagation(); // Prevent event from propagating to the parent
              onRemoveImage(pos.imageIndex); // Handle image removal
            }}
          >
            ×
          </RemoveButton>
          
          )}
        </ImageWrapper>
      ))}
    </GridContainer>
  );
};

export default Layout3;
