import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Nav from '../Components/Nav';
import { FaCheck } from 'react-icons/fa';
import Modal from '../Components/StorageModal';
import Footer from '../Components/Footer';

const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5vh;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 10vh;
  margin-top: 10vh;
  background-color: #fff; /* White background for the top section */

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const TextContainer = styled.div`
  flex: 1;
  margin-top: 0vh;
  padding-left: 8vh;
  padding-right: 2vh;

  h1 {
    font-size: 6vh;
    margin-bottom: 3vh;
    color: #333;
  }

  p {
    margin-top: 5vh;
    font-size: 2.6vh;
    margin-bottom: 2vh;
    color: #666;
  }

  @media (max-width: 768px) {
    padding-right: 0;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
 
   video {
    width: 500px; /* Set desired width */
    height: auto; /* Set desired height */
    border-radius: 1vh;
  }

  img {

    max-width: 80%;
    height: auto;
  }

  @media (max-width: 768px) {
    margin-top: 3vh;
  }
`;

const NewTitle = styled.h2`
  font-size: 5vh;
  margin-bottom: 5vh;
  color: #525150;
`;

const ThreeColumnContainer = styled.div`
  display: flex;
  justify-content: space-around;
  text-align: left;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ThreeColumnGridContainer = styled.div`
  display: grid;
  margin-top: 5vh;
  padding: 0vh 10vh;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto auto; /* Two rows */
  column-gap: 2vh;
  row-gap: 4vh; /* Adjust this to control vertical spacing between rows */

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Stack vertically on small screens */
    grid-template-rows: auto;
    row-gap: 4vh;
  }
`;

const Column = styled.div`
  flex: 1;
  text-align: center;
  padding: 0 2vh;
  max-width: 300px;

  h2 {
    font-size: 3vh;
    margin-bottom: 2vh;
    color: #525150;
  }

  p {
    font-size: 2.3vh;
    color: white;
  }

  @media (max-width: 768px) {
    margin-bottom: 4vh;
    max-width: none;
  }
`;

const ColumnLeft = styled.div`
  flex: 1;
  text-align: left;
  padding: 0 2vh;
  max-width: 300px;

  h2 {
    font-size: 3vh;
    margin-bottom: 2vh;
    color: #525150;
  }

  p {
    font-size: 2.5vh;
    color: white;
  }

  @media (max-width: 768px) {
    margin-bottom: 4vh;
    max-width: none;
  }
`;

const Button = styled(Link)`
  background-color: #0ce846;
  color: white;
  padding: 1.7vh 2.4vh;
  text-decoration: none;
  font-size: 2.4vh;
  font-weight: bold;
  border-radius: 5px;
  margin-top: 5vh;
  display: inline-block;
  transition: transform 0.2s ease-in-out; /* Smooth transition for hover effect */

  &:hover {
    background-color: #00ff44;
    transform: scale(1.05); /* Scale the button to 105% of its size */
  }
`;


const LeftImageContainer = styled.div`
  flex: 1;
  
  img {
    max-width: 80%;
    height: auto;
    border-radius: 4vh;
  }

  @media (max-width: 768px) {
    margin-top: 3vh;
  }
`;

const RightTextContainer = styled.div`
  flex: 1;
  padding-left: 2vh;

  h2 {
    font-size: 5vh;
    margin-bottom: 2vh;
    color: #525150;
  }

  p {
    font-size: 2.5vh;
    color: white;
  }

  @media (max-width: 768px) {
    padding-left: 0;
  }
`;

const LeftTextContainer = styled.div`
  flex: 1;
  padding-right: 10vh;
  text-align: right;
  
  h2 {
    font-size: 5vh;
    margin-bottom: 2vh;
    color: #525150;
  }

  p {
    font-size: 2.5vh;
    color: white;
  }

  @media (max-width: 768px) {
    padding-right: 0;
  }
`;

const RightImageContainer = styled.div`
  flex: 1;

  img {
    max-width: 100%;
    height: auto;
    
  }

  @media (max-width: 768px) {
    margin-top: 3vh;
  }
`;

const IconStyle = {
  color: 'green',
  marginRight: '1vh',
};

const GradientContainer = styled.div`
  background: linear-gradient(
    to bottom, 
    #ffaa42,  /* Top color */
    #ffaa42
  );
  padding-top: 8vh;
`;

const GradientSection = styled.section`
  padding: 8vh 5vh;
  text-align: center;
  color: #f7d77e;
`;

const ImageTextSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5vh;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    text-align: center;
  }
`;

const LeftTextRightImageSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5vh;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const LargeTitleContainer = styled.div`
  display: inline-block;
  text-align: left;
  padding-left: 10vh;
  margin-top: 2vh;

  h2 {
    font-size: 6vh;
    font-weight: bold;
    color: #525150;
    margin-bottom: 10vh; /* No gap between title and divider */
  }
`;

const SectionDivider = styled.div`
  width: 100%; /* Ensures the divider spans the width of the container */
  border-bottom: 4px solid #525150; /* Adjust thickness and color of divider */
  margin-top: 2vh; /* Small space between the title and divider */
  margin-bottom: -3vh;
`;

const PricingContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5vh 10vh;
  margin-top: 5vh;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const CardWrapper = styled.div`
  width: 250px;
`;

const PriceCard = styled.div`
  width: 100%;
  height: 55vh;
  position: relative;
  margin-bottom: 10vh;
  background-color: #fff;
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  padding: 3vh;
  text-align: center;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  }

  h3 {
    font-size: 4vh;
    color: #333;
    margin-bottom: 1.5vh;
  }

  p {
    font-size: 2.2vh;
    color: #666;
    margin-bottom: 2.5vh;
  }

  span {
    font-size: 3vh;
    font-weight: bold;
    color: #333;
    display: block;
    margin-bottom: 2vh;
  }

  .features {
    font-size: 2.2vh;
    color: #333;
    line-height: 1.6; /* Adds spacing between lines */
    margin-bottom: 2vh;
  }
`;

const StorageLink = styled.a`
  color: #3498db;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: #2980b9;
  }
`;

const ExplanationSection = styled.section`
  padding: 8vh 5vh;
  text-align: center;
`;

const ExplanationTitle = styled.h2`
  font-size: 5vh;
  color: #333;
  margin-bottom: 3vh;
`;

const ExplanationText = styled.p`
  font-size: 2.5vh;
  color: #333;
  max-width: 800px;
  margin: 0 auto 3vh;
`;

const ExplanationTextContainer = styled.div`
  background-color: white; /* Add white background */
  width: fit-content;
  padding: 3vh 5vh; /* Add padding for spacing */
  border-radius: 10px; /* Optional: Add border radius for rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for a subtle elevation */
  margin: 0 auto; /* Center the container */
`;

const HomePage: React.FC = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedStorage, setSelectedStorage] = useState(1); // Default to 1GB
  
    const handleModalOpen = (storageSize: number) => {
      setSelectedStorage(storageSize);
      setIsModalOpen(true);
    };
  
    const handleModalClose = () => {
      setIsModalOpen(false);
    };

    return (
      <>
        <Nav />
        <Modal show={isModalOpen} onClose={handleModalClose} storageSize={selectedStorage} />
        <MainContainer>
          <TextContainer>
            <h1>Easily Create Stunning Websites</h1>
            <p><FaCheck style={IconStyle} />Intuitive drag-and-drop builder to design professional websites.</p>
            <p><FaCheck style={IconStyle} />AI-generated content tailored to your business in seconds.</p>
            <p><FaCheck style={IconStyle} />Fully mobile-responsive designs that look great on all devices.</p>
            <Button to="/signin">Get Started Today!</Button>
          </TextContainer>
          <ImageContainer>
  <img src="/images/laptop.png" alt="Laptop Screen">

  </img>
</ImageContainer>

        </MainContainer>
  
        <GradientContainer>
          <GradientSection>
            <NewTitle>One place for your business</NewTitle>
            <ThreeColumnContainer>
              <Column>
                <h2>Build your website</h2>
                <p>Our incredibly user-friendly interface allows anyone to create a stunning website.</p>
              </Column>
              <Column>
                <h2>Make an impression</h2>
                <p>Impress potential clients by showcasing your previous work.</p>
              </Column>
              <Column>
                <h2>Don’t break the bank</h2>
                <p>Everyone should have a website for their business without costing an arm and a leg.</p>
              </Column>
            </ThreeColumnContainer>
          </GradientSection>
  
          <ImageTextSection>
            <LeftImageContainer>
              <img src="/images/construction worker phone.png" alt="Tradesman at work" />
            </LeftImageContainer>
            <RightTextContainer>
              <h2>Customise your perfect website</h2>
              <p>
                Our aim is to allow all tradesmen to showcase their work in a professional way, helping them attract more clients and grow their businesses. No more relying on unprofessional social media pages—our platform gives you a polished online presence that sets you apart. With just a few clicks, you can create a website that highlights your best projects, impresses potential clients, and works perfectly on all devices.
              </p>
            </RightTextContainer>
          </ImageTextSection>
  
          <LeftTextRightImageSection>
            <LeftTextContainer>
              <h2>Easily showcase your work</h2>
              <p>Show off your own images of your previous work to attract new customers. Upload your images and watch us automatically create an incredible gallery of your work.</p>
          
            </LeftTextContainer>
            <RightImageContainer>
              <img src="/images/gallery.png" alt="Showcase of work" />
            </RightImageContainer>
          </LeftTextRightImageSection>

            <ThreeColumnGridContainer>
                <ColumnLeft>
                <h2>Secure browsing</h2>
                <p>Top grade security for your website, ensuring safe and encrypted browsing for all your visitors.</p>
                </ColumnLeft>
                <ColumnLeft>
                <h2>SEO</h2>
                <p>Rank high on search engines and make your business easily discoverable to potential customers.</p>
                </ColumnLeft>
                <ColumnLeft>
                <h2>Fast loading</h2>
                <p>Optimized for speed, ensuring your website loads quickly and provides a seamless experience.</p>
                </ColumnLeft>
                <ColumnLeft>
                <h2>Domain capabilities</h2>
                <p>Link your own domain and give your website a professional and recognizable address.</p>
                </ColumnLeft>
                <ColumnLeft>
                <h2>AI generation</h2>
                <p>Create tailored, high-quality content automatically to engage your audience and enhance your site.</p>
                </ColumnLeft>

            </ThreeColumnGridContainer>
                <LargeTitleContainer>
                    <SectionDivider />
                    <h2>All of this at an unbeatable price</h2>
                </LargeTitleContainer>

                <PricingContainer>
          {/* Card 1 */}
          <CardWrapper>
          <CardWrapper>
                <PriceCard>
                    <h3>Get You Online</h3>
                    <p>Basic but powerful plan to get your business online</p>
                    <span>£5/month</span>
                    <div className="features">
                    SEO ready<br />
                    AI generation<br />
                    Drag and drop builder<br />
                    Create your own domain within ours<br />
                    Professional webpage in seconds<br />
                    <StorageLink onClick={() => handleModalOpen(1)}>1GB of storage space</StorageLink>
                    </div>
                </PriceCard>
                </CardWrapper>
          </CardWrapper>

          <CardWrapper>
            <PriceCard>
                <h3>Domain Connect</h3>
                <p>Get more storage and connect your domain</p>
                <span>£10/month</span>
                <div className="features">
                SEO ready<br />
                AI generation<br />
                Drag and drop builder<br />
                Professional webpage in seconds<br />
                Connect your own domain<br />
                <StorageLink onClick={() => handleModalOpen(5)}>3GB of storage space</StorageLink>
                </div>
            </PriceCard>
            </CardWrapper>

         <CardWrapper>
            <PriceCard>
                <h3>Ultimate Storage</h3>
                <p>8GB storage for your growing business, capable of handling many visitors</p>
                <span>£20/month</span>
                <div className="features">
                SEO ready<br />
                AI generation<br />
                Drag and drop builder<br />
                Professional webpage in seconds<br />
                Connect your own domain<br />
                <StorageLink onClick={() => handleModalOpen(10)}>8GB of storage space</StorageLink>
                </div>
            </PriceCard>
            </CardWrapper>

        </PricingContainer>

        <ExplanationSection>
        <ExplanationTitle>Why Are Our Prices So Low?</ExplanationTitle>
        <ExplanationTextContainer>
        <ExplanationText>
          Other website builders are often overly complex and cater to a wide range of industries, 
          which makes them more expensive and harder to use. They don’t directly support the specific 
          needs of tradesmen like landscapers, builders, roofers, and more.
        </ExplanationText>
        <ExplanationText>
          At <strong>TrdSite</strong>, we understand exactly how a tradesman's website should be built. 
          By focusing solely on trades businesses, we’ve designed an intuitive, easy-to-use platform that 
          simplifies website creation.
        </ExplanationText>
        <ExplanationText>
          Because of this niche focus, we are able to keep our running costs down and pass the savings 
          on to you. This means you get a high-quality, professional website without the high price tag.
        </ExplanationText>
        <ExplanationText>
          Our mission is to support trades businesses by offering the most affordable, effective websites 
          that cater specifically to their industry needs.
        </ExplanationText>
        </ExplanationTextContainer>
        <Button to="/signin">Get Started Today!</Button>
      </ExplanationSection>
        
        </GradientContainer>
        <Footer />
      </>
    );
  };
  
  export default HomePage;