import React from 'react';
import styled from 'styled-components';
import Nav from '../Components/Nav';
import Footer from '../Components/Footer';
import { Link } from 'react-router-dom';

const PageContainer = styled.div`
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const HeaderContainer = styled.div`
  background-image: url('/images/tradesitebackground.png');
  background-size: cover;
  background-position: center;
  padding: 10vh 10vw;
  margin-top: 11vh;
  height: 45vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const HeaderTitle = styled.h1`
  color: white;
  font-size: 4.5vh;
  font-weight: bold;
`;

const HeaderDescription = styled.p`
  font-size: 2.5vh;
  color: white;
  font-weight: bold;
`;

const SubHeaderDescription = styled.p`
  font-size: 2.5vh;
  color: white;
`;

const ContentContainer = styled.div`
  background-color: white;
  padding: 5vh 10vw;
  text-align: center;
  flex: 1;
`;

const PricingCardContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 2vw;
  margin-top: 5vh;
  margin-bottom: 5vh;
  padding: 5vh;
`;

const PricingCard = styled.div`
  background-color: #ffaa42; /* Orange Color */
  color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 3vh 1vh;
  width: 30%;
  text-align: center;

  h2 {
    font-size: 3vh;
    margin-bottom: 2vh;
    color: #525150;
  }

  p {
    font-size: 2.5vh;
    margin-bottom: 2vh;
  }
`;

const Button = styled(Link)`
  background-color: #0ce846;
  color: white;
  padding: 1.7vh 2.4vh;
  text-decoration: none;
  font-size: 2.4vh;
  font-weight: bold;
  border-radius: 5px;
  margin-top: 5vh;
  display: inline-block;
  transition: transform 0.2s ease-in-out; /* Smooth transition for hover effect */

  &:hover {
    background-color: #00ff44;
    transform: scale(1.05); /* Scale the button to 105% of its size */
  }
`;

const BoldHeader = styled.h2`
  font-weight: bold;
  font-size: 2.3vh;
  margin-bottom: 1vh;
`;

const SmallText = styled.p`
  font-size: 2.8vh;
  color: #666;
  margin-bottom: 3vh;
`;

const FeatureLink = styled(Link)`
  color: #ffaa42;
  font-size: 2.5vh;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const FAQContainer = styled.div`
  margin-top: 5vh;
  text-align: left;
  padding: 5vh 10vw;
`;

const QuestionTitle = styled.h3`
  font-size: 3vh;
  font-weight: bold;
  color: #ffaa42;
  margin-bottom: 2vh;
`;

const AnswerText = styled.p`
  font-size: 2.2vh;
  color: #666;
  line-height: 1.6;
  margin-bottom: 4vh;
`;

const TryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2vw; /* Space between the text and button */
  margin-top: 4vh;
  margin-bottom: 5vh;
`;

const TryHeader = styled.h2`
  font-size: 3vh;
  margin-top: 7vh;
  font-weight: bold;
  color: #525150;
`;

const PricingPage: React.FC = () => {
  return (
    <>
      <Nav />
      <PageContainer>
        {/* Header Section */}
        <HeaderContainer>
          <HeaderTitle>PRICING PLANS</HeaderTitle>
          <HeaderDescription>Start building your website now! Or find out which of our plans suits you best.</HeaderDescription>
          <SubHeaderDescription>We have a choice of three different plans, depending on your needs as a business, all plans allow control over your number of visitors each month.</SubHeaderDescription>
          <Button to="/signin">Get Started Today!</Button>
        </HeaderContainer>

        {/* Pricing Section */}
        <ContentContainer>
          {/* Additional header and text */}
          <BoldHeader>Find out more about what our plans include <FeatureLink to="/features">Features →</FeatureLink></BoldHeader>
          <SmallText>All plans are on a monthly basis. No hidden costs.</SmallText>

          <PricingCardContainer>
            <PricingCard>
              <h2>Get You Online</h2>
              <p>£5 / month</p>
              <p>SEO ready.</p>
              <p>AI generated content.</p>
              <p>Create your own domain within ours</p>
              <p>100 Visitors per month included with the option to add more.</p>
              <p>1GB storage.</p>
            </PricingCard>


            <PricingCard>
              <h2>Domain Connect</h2>
              <p>£10 / month</p>
              <p>SEO ready.</p>
              <p>AI generated content.</p>
              <p>Connect your own domain.</p>
              <p>150 Visitors per month included with the option to add more.</p>
              <p>3GB storage.</p>
            </PricingCard>

            <PricingCard>
              <h2>More Storage</h2>
              <p>£20 / month</p>
              <p>SEO ready.</p>
              <p>AI generated content.</p>
              <p>Connect your own domain.</p>
              <p>250 Visitors per month included with the option to add more.</p>
              <p>8GB storage.</p>
            </PricingCard>
          </PricingCardContainer>
        </ContentContainer>

        <FAQContainer>
      <QuestionTitle>What is storage space?</QuestionTitle>
      <AnswerText>
        Storage space is the amount of images you can upload. Depending on your image file sizes you can expect an allowance of (x) per GB of storage.
      </AnswerText>

      <QuestionTitle>How many visitors can I have per month?</QuestionTitle>
      <AnswerText>
        Plans come with a minimum amount of visitors to your website. You have the option to upgrade this on a monthly basis up to an added 1000 visitors per month, so your costs of running your site is in your hands.
      </AnswerText>

      <QuestionTitle>What if I exceed the amount of visitors in my month without paying for any additional visitors?</QuestionTitle>
      <AnswerText>
        We will keep track and let you know if you are coming up to your allowance each month. Then you can have the option to buy more, if not we will display your contact details and any other means of communication for your customers directly to you. This will refresh each month.
      </AnswerText>

      <QuestionTitle>Am I locked in to a contract?</QuestionTitle>
      <AnswerText>
        Nope! You pay on a monthly basis, if you decide you no longer need our platform you can cancel at any time, your site will be up and running until your month is up.
      </AnswerText>
      <QuestionTitle>I have no experience with websites, how easy is TrdSite to use?</QuestionTitle>
      <AnswerText>
        TrdSite is designed specifically for Tradesmen and Tradeswoman. We have created our software, so all you need to do is drag and drop to create your website. Once you begin, tell us a little about your company and we will create your whole website in a single click.
      </AnswerText>
      <TryContainer>
      <TryHeader>Try before you buy!</TryHeader>
      <Button to="/signin">Get Started Today!</Button>
      </TryContainer>
    </FAQContainer>

        <Footer />
      </PageContainer>
    </>
  );
};

export default PricingPage;
