// Modal.tsx
import React from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 4vh;
  border-radius: 10px;
  max-width: 500px;
  text-align: center;
`;

const CloseButton = styled.button`
  background-color: #f44336;
  color: white;
  border: none;
  padding: 1vh 2vh;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 2vh;
`;

const Modal: React.FC<{ show: boolean; onClose: () => void; storageSize: number }> = ({ show, onClose, storageSize }) => {
  if (!show) return null;

  const getStorageDetails = () => {
    switch (storageSize) {
      case 1:
        return {
          images: "40 to 500",
          visitors: 100,
          text: "With 1GB of storage, you can host approximately 40 to 500 images (depending on image sizes ranging from 2MB to 25MB). You can expect up to 100 visitors per month with the option to add on additional monthly visitors.",
        };
      case 5:
        return {
          images: "120 to 1500",
          visitors: 150,
          text: "With 3GB of storage, you can host approximately 120 to 1500 images (depending on image sizes from 2MB to 25MB). You can expect up to 150 visitors per month with the option to add on additional monthly visitors.",
        };
      case 10:
        return {
          images: "320 to 4000",
          visitors: 250,
          text: "With 8GB of storage, you can host approximately 320 to 4000 images (depending on image sizes from 2MB to 25MB). You can expect up to 250 visitors per month with the option to add on additional monthly visitors.",
        };
      default:
        return { images: 0, visitors: 0, text: "Invalid storage size." };
    }
  };
  

  const { text } = getStorageDetails();

  return (
    <ModalOverlay>
      <ModalContent>
        <h2>What does storage space mean?</h2>
        <p>{text}</p>
        <CloseButton onClick={onClose}>Close</CloseButton>
      </ModalContent>
    </ModalOverlay>
  );
};

export default Modal;
